<template>
	<div class="convention-hall page">
		<van-nav-bar class="nav-bar" :title="$t('国外')" />
		
		<div class="container">
			<div class="row">
				<div class="left-home col-lg-9 col-md-9 col-sm-9 col-xs-12">
					<div class="pageone">
						<div class="pageone-title" style="margin-bottom: -10PX;">
							<h2><a style='float:left;font-size: 15PX;margin-bottom: -10px;' href="javascript:void(0);" title="">LATEST AUSTRALIAN JOBS</a></h2>
							<p class="line-do"></p>
						</div>
						<div class="pageone-tab">
							<ul class="nav nav-tabs" role="tablist" style="display: flex;flex-wrap: wrap;">
								<li class="active" style="background: #f58220;display: flex;align-items: center;justify-content: center;"><a href="javascript:void(0);" role="tab" data-toggle="tab">All new orders</a></li>
								<li><a href="javascript:void(0);" style='height: 32px;' role="tab" data-toggle="tab">Engineer go to Australia</a></li>
								<li><a href="javascript:void(0);" style='height: 32px;' role="tab" data-toggle="tab">Orders for men</a></li>
								<li><a href="javascript:void(0);" style='height: 32px;' role="tab" data-toggle="tab">Order for women</a></li>
							</ul>
							<div class="tab-content" id="style-2">
								<div class="tab-pane active" id="thong-so-ki-thuat">
									<table class="donhang-home">
										<tbody>
											<tr>
												<td>Industry</td>
												<td>Exam date</td>
												<td>Sex</td>
												<td>Basic salary</td>
												<td>Quantity</td>
											</tr>
											<tr>
												<td><a href="javascript:void(0);" title="[Total fee 105 million] Food processing order for women going to Australia">Finance</a></td>
												<td>15/09/2023</td>
												<td>Female</td>
												<td>3500 $ </td>
												<td>30</td>
												
											</tr>
											<tr>
												<td><a href="javascript:void(0);" title="Tokutei Food's first Vietnamese order to make dumplings in Gunma province">Estate</a></td>
												<td>25/09/2023</td>
												<td>Male/Female</td>
												<td>4000 $ </td>
												<td>24</td>
												
											</tr>
											<tr>
												<td><a href="javascript:void(0);" title="Recruitment for vocational study abroad in Australia, study and work, opportunity to earn 4000 AAD">Factory</a></td>
												<td>28/09/2023</td>
												<td>Male Female</td>
												<td>2500 $ </td>
												<td>100</td>
												
											</tr>
											<tr>
												<td><a href="javascript:void(0);" title="Food processing order in Australia recruiting 18 women">Doctor</a></td>
												<td>18/09/2023</td>
												<td>Female</td>
												<td>4300 $ </td>
												<td>18</td>
												
											</tr>
											<tr>
												<td><a href="javascript:void(0);" title="Recruiting 30 Female for industrial packaging work in Australia in Tokyo province">Science and technology</a></td>
												<td>16/09/2023</td>
												<td>Female</td>
												<td>4000 $ </td>
												<td>30</td>
												
											</tr>
											<tr>
												<td><a href="javascript:void(0);" title="Traditional order recruiting 100 female and 20 male to process AUSTRALIAN seafood">Hotel manager</a></td>
												<td>25/09/2023</td>
												<td>Male Female</td>
												<td>3600 $ </td>
												<td>120</td>
												
											</tr>
											<tr>
												<td><a href="javascript:void(0);" title="Metal stamping order for Nissan Australia auto parts recruiting 54 men">Mechanical</a></td>
												<td>24/09/2023</td>
												<td>Male </td>
												<td>2200 $ </td>
												<td>54</td>
												
											</tr>
											<tr>
												<td><a href="javascript:void(0);" title="Order for processed food in Australia is recruiting 60 Females to work in supermarkets">Food</a></td>
												<td>15/09/2023</td>
												<td>Female</td>
												<td>2000 $ </td>
												<td>60</td>
												
											</tr>
											<tr>
												<td><a href="javascript:void(0);" title="Food processing order in Kyoto, Australia, recruiting 54 women">Food</a></td>
												<td>24/09/2023</td>
												<td>Female</td>
												<td>2000 $ </td>
												<td>54</td>
												
											</tr>
											<tr>
												<td><a href="javascript:void(0);" title="AUSTRALIAN factory order recruiting 20 men in Osaka province">Classify</a></td>
												<td>29/09/2023</td>
												<td>Male </td>
												<td>2100 $ </td>
												<td>20</td>
												
											</tr>
										</tbody>
									</table>
									<!-- <div class="xemthemhome"><a href="javascript:void(0);" title="See more">See more</a></div> -->
								</div>
								
								
							
							</div>
						</div>
					</div>
					<!-- Tin việc làm nước ngoài mới nhất -->
					<div class="widget widget-highlight-job pagetow" >
						<h2 class="widget-title" style="display: flex;flex-wrap: wrap;">
							<a href="javascript:void(0);" title="Latest Overseas Jobs">Latest Overseas Jobs 
							<font style="float:right; color:red; text-transform:none; font-size:13px; margin-left:10px;    text-decoration: underline;">View more</font></a> </h2>
						<div class="clearfix"></div>
						<div class="widget-wrap scrollbar">
							<div class="widget-content" style="display: none;">
								<ul>
									<li>
										<a href="javascript:void(0);" title="Recruitment for vocational study abroad in Australia, study and work, opportunity to earn 4000 AAD">
											<h3>Recruitment for vocational study abroad in Australia, study and work, opportunity to earn 4000 AAD</h3>
											<h4><span>Salary:</span>4000 $ </h4>
											<h4><span>Nation:</span>Australia</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="41 latest Singapore orders recruiting continuously">
											<h3>41 latest Singapore orders recruiting continuously</h3>
											<h4><span>Salary:</span>40000 </h4>
											<h4><span>Nation:</span>Singapore</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Admission to study in Australia without IELTS English certificate">
											<h3>Admission to study in Australia without IELTS English certificate</h3>
											<h4><span>Salary:</span>25 $/hour </h4>
											<h4><span>Nation:</span>Australia</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Exporting labor to Poland, recruiting male and female workers. Packing">
											<h3>Exporting labor to Poland, recruiting male and female workers. Packing</h3>
											<h4><span>Salary:</span>1.100 USD </h4>
											<h4><span>Nation:</span>Ba lan</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Polish labor export recruits male and female for food work">
											<h3>Polish labor export recruits male and female for food work</h3>
											<h4><span>Salary:</span>1.100 USD </h4>
											<h4><span>Nation:</span>Ba lan</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Notice of German labor export program with outstanding fees">
											<h3>Notice of German labor export program with outstanding fees</h3>
											<h4><span>Salary:</span>80 million </h4>
											<h4><span>Nation:</span>CHLB Đức</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="E7 Visa Korea is recruiting mechanics and electricians to work at a shipyard">
											<h3>E7 Visa Korea is recruiting mechanics and electricians to work at a shipyard</h3>
											<h4><span>Salary:</span>2,7million won </h4>
											<h4><span>Nation:</span>Korea</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Construction order in Singapore recruits 1,300 unskilled workers">
											<h3>Construction order in Singapore recruits 1,300 unskilled workers</h3>
											<h4><span>Salary:</span>3000 SGD </h4>
											<h4><span>Nation:</span>Singapore</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Notice of recruitment of workers to work in Germany">
											<h3>Notice of recruitment of workers to work in Germany</h3>
											<h4><span>Salary:</span>2400 Eur </h4>
											<h4><span>Nation:</span>Đức</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Philippines labor export recruits Sales Marketing staff with 0 VND fee">
											<h3>Philippines labor export recruits Sales Marketing staff with 0 VND fee</h3>
											<h4><span>Salary:</span>36 million </h4>
											<h4><span>Nation:</span>Philippines</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Labor export to Australia Australia recruits 1500 male and female workers for agriculture">
											<h3>Labor export to Australia Australia recruits 1500 male and female workers for agriculture</h3>
											<h4><span>Salary:</span>85 million </h4>
											<h4><span>Nation:</span>Australia Australia</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Hiring chefs to work in Germany, no language required, just insurance required">
											<h3>Hiring chefs to work in Germany, no language required, just insurance required</h3>
											<h4><span>Salary:</span>2.800 Euro </h4>
											<h4><span>Nation:</span>CHLB Đức</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="New Zealand labor export order recruiting male female restaurant waiter">
											<h3>New Zealand labor export order recruiting male female restaurant waiter</h3>
											<h4><span>Salary:</span>80 million </h4>
											<h4><span>Nation:</span>New Zealand</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Recruiting construction workers in New Zealand">
											<h3>Recruiting construction workers in New Zealand</h3>
											<h4><span>Salary:</span>4000- 6,500 NZD </h4>
											<h4><span>Nation:</span>New Zealand</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Agriculture industry recruits male and female farm workers in New Zealand">
											<h3>Agriculture industry recruits male and female farm workers in New Zealand</h3>
											<h4><span>Salary:</span>5000-5500 NZ </h4>
											<h4><span>Nation:</span>New Zealand</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Recruiting welders to go to Hungary and Slovakia, Europe, low cost, high salary">
											<h3>Recruiting welders to go to Hungary and Slovakia, Europe, low cost, high salary</h3>
											<h4><span>Salary:</span>2.200 Euro </h4>
											<h4><span>Nation:</span>Hungary</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Exporting labor to Hungary with 5 new recruitment orders">
											<h3>Exporting labor to Hungary with 5 new recruitment orders</h3>
											<h4><span>Salary:</span>1.100 USD </h4>
											<h4><span>Nation:</span>Hungary</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="3 orders for exporting Hungarian labor to work as Interpreters, foremen, and electronics">
											<h3>3 orders for exporting Hungarian labor to work as Interpreters, foremen, and electronics</h3>
											<h4><span>Salary:</span>2000USD </h4>
											<h4><span>Nation:</span>Hungary</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Taiwan labor export recruits 15 male and female workers to produce treadmills in Taichung">
											<h3>Taiwan labor export recruits 15 male and female workers to produce treadmills in Taichung</h3>
											<h4><span>Salary:</span>23.800 Đài tệ </h4>
											<h4><span>Nation:</span>Đài Loan</h4> </a>
									</li>
									<li>
										<a href="javascript:void(0);" title="Recruiting restaurant assistants and chefs in Romania">
											<h3>Recruiting restaurant assistants and chefs in Romania</h3>
											<h4><span>Salary:</span>1000 USD </h4>
											<h4><span>Nation:</span>Rumani</h4> </a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="clear"></div>
					<div class="zwBox">
						<div class="zwBox-item">
							<div class="zwBox-item-top">
								<van-icon name="bookmark-o" />
							</div>
							<div class="zwBox-item-logo">
								<img src="https://bx-branding-gateway.cloud.seek.com.au/aa7e0f01-7c97-40bb-bb78-cd0f04ca03ac.1/serpLogo" loading="lazy">
							</div>
							<div class="zwBox-item-title">Casual Parcel Sorters PM Shift</div>
							<div class="zwBox-item-tips">Border Express Pty Ltd</div>
							<div class="zwBox-item-text">Chennai</div>
							<div class="zwBox-item-ul">
								<div class="zwBox-item-banci">
									<div class="zwBox-item-banci-slider"></div>
									<div>Equal Opportunity Employer</div>
								</div>
								<div class="zwBox-item-banci">
									<div class="zwBox-item-banci-slider"></div>
									<div>Great work/life balance</div>
								</div>
								<div class="zwBox-item-banci">
									<div class="zwBox-item-banci-slider"></div>
									<div>Opportunities for career progression</div>
								</div>
							</div>
							<div class="zwBox-item-jiesao">The Border Express Wetherill Park branch currently has fantastic opportunities available for Parcel Sorters to join the PM team.</div>
							<div class="zwBox-item-time">10h ago</div>
						</div>
						<div class="zwBox-item">
							<div class="zwBox-item-top">
								<van-icon name="bookmark-o" />
							</div>
							<!-- <div class="zwBox-item-logo">
								<img src="https://bx-branding-gateway.cloud.seek.com.au/65420818-9480-0acf-040f-ad9606a6f024.1/serpLogo" loading="lazy">
							</div> -->
							<div class="zwBox-item-title">Process worker</div>
							<div class="zwBox-item-tips">Hyderabad</div>
							<div class="zwBox-item-text">$30 – $32 per hour</div>
							<!-- <div class="zwBox-item-ul">
								<div class="zwBox-item-banci">
									<div class="zwBox-item-banci-slider"></div>
									<div>RM班次</div>
								</div>
								<div class="zwBox-item-banci">
									<div class="zwBox-item-banci-slider"></div>
									<div>RM班次</div>
								</div>
								<div class="zwBox-item-banci">
									<div class="zwBox-item-banci-slider"></div>
									<div>RM班次</div>
								</div>
							</div> -->
							<div class="zwBox-item-jiesao">We have an opportunity for a process worker to join our team of casuals.</div>
							<div class="zwBox-item-time">10h ago</div>
						</div>
						<div class="zwBox-item">
							<div class="zwBox-item-top">
								<van-icon name="bookmark-o" />
							</div>
							<div class="zwBox-item-logo">
								<img src="https://bx-branding-gateway.cloud.seek.com.au/b8f50eaf-6f0b-2ba4-77b8-1e871ae2fe03.1/serpLogo" loading="lazy">
							</div>
							<div class="zwBox-item-title">Production Line Attendant $32.23 </div>
							<div class="zwBox-item-tips">Strategy One Human Resources</div>
							<div class="zwBox-item-text">Pune,weekly pay</div>
							<div class="zwBox-item-ul">
								<div class="zwBox-item-banci">
									<div class="zwBox-item-banci-slider"></div>
									<div>Ongoing Monday to Friday</div>
								</div>
								<div class="zwBox-item-banci">
									<div class="zwBox-item-banci-slider"></div>
									<div>Weekly Pay!</div>
								</div>
								<div class="zwBox-item-banci">
									<div class="zwBox-item-banci-slider"></div>
									<div>Great Work Culture</div>
								</div>
							</div>
							<div class="zwBox-item-jiesao">Our client based in Pune is after an Production Line Attendant for an ASAP start. APPLY NOW START TOMORROW!</div>
							<div class="zwBox-item-time">14h ago</div>
						</div>
						
						<div class="zwBox-item">
							<div class="zwBox-item-top">
								<van-icon name="bookmark-o" />
							</div>
							<div class="zwBox-item-logo">
								<img src="https://bx-branding-gateway.cloud.seek.com.au/f31cbf2b-ea6d-666a-116a-716dba6f3e68.1/serpLogo" loading="lazy">
							</div>
							<div class="zwBox-item-title">COLD STORAGE PICKER</div>
							<div class="zwBox-item-tips">Trivandrum</div>
							<div class="zwBox-item-text">$39 – $42 per hour</div>
							<div class="zwBox-item-ul">
								<div class="zwBox-item-banci">
									<div class="zwBox-item-banci-slider"></div>
									<div>FUTURE OF WAREHOUSING - Join a company that is paving the way in cold storage</div>
								</div>
								<div class="zwBox-item-banci">
									<div class="zwBox-item-banci-slider"></div>
									<div>GROW YOUR CAREER - Pathways to management, administration, planning, & more</div>
								</div>
								<div class="zwBox-item-banci">
									<div class="zwBox-item-banci-slider"></div>
									<div>LONG-TERM EMPLOYMENT - strong view to permanency</div>
								</div>
							</div>
							<div class="zwBox-item-jiesao">Join a fully automated cold storage facility offering temp-to-perm roles & excellent career progression. Seeking skilled and motivated frozen pickers!</div>
							<div class="zwBox-item-time">8h ago</div>
						</div>
						
						<div class="zwBox-item">
							<div class="zwBox-item-top">
								<van-icon name="bookmark-o" />
							</div>
							<div class="zwBox-item-logo">
								<img src="https://bx-branding-gateway.cloud.seek.com.au/50639290-06d8-4bfb-a0bf-999e6e333c8b.1/serpLogo" loading="lazy">
							</div>
							<div class="zwBox-item-title">PRODUCTION WORKERS</div>
							<div class="zwBox-item-tips">Scholle IPN Pty Ltd</div>
							<div class="zwBox-item-text">Pune</div>
							<div class="zwBox-item-ul">
								<div class="zwBox-item-banci">
									<div class="zwBox-item-banci-slider"></div>
									<div>Guaranteed FULL-TIME hours with a permanent role - NO ROTATING ROSTERS</div>
								</div>
								<div class="zwBox-item-banci">
									<div class="zwBox-item-banci-slider"></div>
									<div>Accrue annual leave, rostered days off and annual bonus scheme</div>
								</div>
								<div class="zwBox-item-banci">
									<div class="zwBox-item-banci-slider"></div>
									<div>Great work-life balance with a Monday to Friday working week</div>
								</div>
							</div>
							<div class="zwBox-item-jiesao">SIG are seeking production workers to join our dynamic production team in Edinburgh North in FULL-TIME permanent roles.</div>
							<div class="zwBox-item-time">15h ago</div>
						</div>
					</div>
					<!-- //// -->
					<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6n" style="display: none;">
											<div class="_1ungv2r0" data-search-sol-meta="{&quot;searchRequestToken&quot;:&quot;57ffa085-d70e-4a32-8a9f-976c323d9f9b&quot;,&quot;token&quot;:&quot;0~57ffa085-d70e-4a32-8a9f-976c323d9f9b&quot;,&quot;jobId&quot;:&quot;80256625&quot;,&quot;section&quot;:&quot;MAIN&quot;,&quot;sectionRank&quot;:1,&quot;jobAdType&quot;:&quot;ORGANIC&quot;,&quot;tags&quot;:{&quot;mordor__flights&quot;:&quot;mordor_530&quot;,&quot;mordor__s&quot;:&quot;0&quot;,&quot;seek:chalice:experiments:remote_search_filter&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:behavioural_cues_filters&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:secondary_filters&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:dynamic_pills&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:serp_job_card_info_density_1&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:serp_google_one_tap&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:matched_qualities&quot;:&quot;2&quot;}}">
												<article class="_1ungv2r0 _1ungv2r1 _1viagsn8n _1viagsn8o _1viagsn7j _1viagsn7k _1viagsnav _1viagsnaw _1viagsn9r _1viagsn9s _1viagsnh _1viagsn67 _1viagsn5f _1st1fyjb _1st1fyj9 _1st1fyja _3h66av18 _3h66av1b _1viagsn33 _1viagsn36" data-automation="normalJob" data-testid="job-card" data-job-id="80256625" data-card-type="JobCard" id="jobcard-1" aria-label="Freight Handler" aria-selected="false">
													<div class="_1ungv2r0 _1viagsn4z _1viagsn4x">
														<a href="javascript:void(0);" ddd="/job/80256625?type=standout&amp;ref=search-standalone#sol=5cc36bb626bb46e3d9fe7539852853d7ededfde5" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _1viagsn5j _1viagsnj _1viagsnk _1viagsnl _1viagsnm _1viagsn7" data-automation="job-list-view-job-link" target="_blank"></a>
													</div>
													<div class="_1ungv2r0 _1viagsn4v _1viagsn51">
														<a href="javascript:void(0);" ddd="/job/80256625?type=standout&amp;ref=search-standalone&amp;origin=jobCard#sol=5cc36bb626bb46e3d9fe7539852853d7ededfde5" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _1viagsn5j _1viagsnj _1viagsnk _1viagsnl _1viagsnm _1viagsn7 _1st1fyj4" data-automation="job-list-item-link-overlay" data-testid="job-list-item-link-overlay" data-run-click-only="true" target="_self"></a>
													</div>
													<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6v">
														<div class="_1ungv2r0 _1viagsn5b _1viagsnh7 _1viagsngr _157hsn62d">
															<div class="_1ungv2r0 _1viagsn4z _1viagsnr _1viagsnp _1viagsni3 _1viagsnb7">
																<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6n">
																	<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6r">
																		<div class="_1ungv2r0 khwnjl1" data-testid="company-logo-container" data-automation="company-logo-container">
																			<div class="_1ungv2r0 _1viagsn5b _1viagsnn" data-automation="company-logo"><img class="khwnjl0" src="https://bx-branding-gateway.cloud.seek.com.au/65420818-9480-0acf-040f-ad9606a6f024.1/serpLogo" loading="lazy"></div>
																		</div>
																	</div>
																	<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6r">
																		<div class="_1ungv2r0">
																			<h3 class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av3 _3h66av21 _1708b944 _3h66ava"><div class="_1ungv2r0 _1viagsn5h _1viagsn53"><a href="javascript:void(0);" ddd="/job/80256625?type=standout&amp;ref=search-standalone&amp;origin=cardTitle#sol=5cc36bb626bb46e3d9fe7539852853d7ededfde5" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _1st1fyje _1st1fyjg" id="job-title-80256625" data-automation="jobTitle" data-testid="job-card-title" data-run-click-only="true" target="_self">Freight Handler</a></div></h3>
																			<div class="_1ungv2r0 _1viagsn7v"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66ava"><div class="_1ungv2r0 _1viagsn5h _1viagsn53"><span class="_1ungv2r0 _1viagsn5j _1viagsn0 _17tnjda0">at<!-- --> </span><a href="javascript:void(0);" ddd="/Wymap-Group-jobs" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _8x0b970 _8x0b971" aria-label="Jobs at Wymap Group" title="Jobs at Wymap Group" data-type="company" data-automation="jobCompany" target="_self">Wymap People</a></div>
																			</span>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="_1ungv2r0 _1viagsn4z _1viagsnr _1viagsnhv _1viagsnhz _1viagsnb7">
															<div class="_1ungv2r0 _1viagsn5h _1viagsn53">
																<div class="_1ungv2r0 _157hsn60 _157hsn64 _157hsn61 _157hsn618 _157hsn62c _157hsn63g">
																	<div class="_1ungv2r0 _1viagsn5f"><button class="_1ungv2r0 _1ungv2r7 _1viagsn8v _1viagsn7r _1viagsnb3 _1viagsn9z _1viagsn7 _1viagsnw _1viagsn5v _1viagsn5f _1viagsn4z _1viagsnz _1viagsny _1viagsn5 _1viagsnib _1viagsn4 _1viagsnh _4z9l0z0 _4z9l0z6 _12i37nn0 uf4ir0" type="button" id="serp-signed-out-save-job-80256625" aria-label="Sign in to save this job" tabindex="0" data-testid="signed-out-save-job" data-automation="signed-out-save-job"><span class="_1ungv2r0 _1viagsnj _1viagsnk _1viagsnl _1viagsnm _1viagsn5j _1viagsni _1viagsn5v _1viagsnx _1viagsn6 di8fio0 _4z9l0z4 _1viagsn4t _1viagsn4u"></span><span class="_1ungv2r0 _1viagsnj _1viagsnk _1viagsnl _1viagsnm _1viagsn5j _1viagsni _1viagsn5v _1viagsnx _1viagsn6 _4z9l0z3 _4z9l0zi _3h66av18 _3h66av1a _1viagsn2t _1viagsn2u"></span><span class="_1ungv2r0 _1viagsnj _1viagsnk _1viagsnl _1viagsnm _1viagsn5j _1viagsni _1viagsn5v _1viagsnx _1viagsn6 _4z9l0z2 _4z9l0zj _3h66av18 _3h66av1a _1viagsn2r _1viagsn2s"></span><span class="_1ungv2r0 _1viagsn4z _1viagsn5f qa05hu0 _3h66avi qa05hu1"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" xml:space="preserve" focusable="false" fill="currentColor" width="16" height="16" class="_1ungv2r0 _1viagsnp _1viagsnn _1viagsn4z _3h66av21" aria-hidden="true"><path d="M19 5.1c.1-1.6-1.1-2.9-2.7-3.1H7.6C6.1 2.1 4.9 3.5 5 5v16c0 .4.2.7.5.9.3.2.7.2 1 0l5.4-3.6 5.4 3.6c.2.1.4.2.6.2.2 0 .3 0 .5-.1.3-.2.5-.5.5-.9l.1-16zm-2 14-4.4-3c-.3-.2-.8-.2-1.1 0l-4.4 3L7 4.9c0-.4.3-.9.7-.9h8.5c.5 0 .8.5.8 1v14.1z"></path></svg></span></button></div>
																</div>
															</div>
														</div>
													</div>
													<div class="_1ungv2r0 _1viagsn5j _1viagsn0 _17tnjda0">
														<p class="_1ungv2r0">This is a Casual/Vacation job</p>
													</div>
													<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6n">
														<div class="_1ungv2r0"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7"><span class="_1ungv2r0" data-automation="jobCardLocation"><a href="javascript:void(0);" ddd="/jobs/in-Erskine-Park-NSW-2759?classification=6205%2C6092" rel="nofollow" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _8x0b970 _8x0b972" aria-label="Limit results to Erskine Park" tabindex="-1" title="Limit results to Erskine Park" data-type="location" data-automation="jobLocation" target="_self">Erskine Park</a></span><span class="_1ungv2r0" data-automation="jobCardLocation">, <a href="javascript:void(0);" ddd="/jobs/in-All-Sydney-NSW?classification=6205%2C6092" rel="nofollow" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _8x0b970 _8x0b972" aria-label="Limit results to Sydney NSW" tabindex="-1" title="Limit results to Sydney NSW" data-type="location" data-automation="jobLocation" target="_self">Sydney NSW</a></span></span>
														</div>
														<div class="_1ungv2r0" aria-label="Salary: $35 to $42 Per Hour"><span class="_1ungv2r0 _1viagsn4z _1viagsnr _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7 ndjyux0" data-automation="jobSalary"><span class="_1ungv2r0 ndjyux2 _1viagsn4z _1viagsn0 _1viagsnr ndjyux4" style="--ndjyux3:2">$35 to $42 Per Hour</span></span>
														</div>
														<div class="_1ungv2r0 _1viagsn4v _1viagsn50"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7"><div class="_1ungv2r0 _1viagsn5b _1viagsnh7 _1viagsngf _1viagsn7f _1viagsnhn _1suz4l60"><span class="_1ungv2r0 _1viagsn5j _1viagsn0 _17tnjda0">subClassification: Warehousing, Storage &amp; Distribution</span><a href="javascript:void(0);" ddd="/jobs-in-manufacturing-transport-logistics/warehousing-storage-distribution" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _8x0b970 _8x0b972" aria-label="Limit results to Warehousing, Storage &amp; Distribution in Manufacturing, Transport &amp; Logistics" tabindex="-1" title="Limit results to Warehousing, Storage &amp; Distribution in Manufacturing, Transport &amp; Logistics" data-type="subClassification" data-automation="jobSubClassification" target="_self">Warehousing, Storage &amp; Distribution</a>
															<div class="_1ungv2r0 _1viagsnff"></div><span class="_1ungv2r0 _1viagsn5j _1viagsn0 _17tnjda0">classification: Manufacturing, Transport &amp; Logistics</span><a href="javascript:void(0);" ddd="/jobs-in-manufacturing-transport-logistics" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _8x0b970 _8x0b972" aria-label="Limit results to Manufacturing, Transport &amp; Logistics" tabindex="-1" title="Limit results to Manufacturing, Transport &amp; Logistics" data-type="classification" data-automation="jobClassification" target="_self">(Manufacturing, Transport &amp; Logistics)</a></div>
														</span>
													</div>
											</div>
											<ul class="_1ungv2r0 _1ungv2r3 _1viagsn5b _1viagsnhf _1viagsn6n _1viagsni7">
												<li class="_1ungv2r0 _1viagsn5b">
													<div class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7">
														<div class="_1ungv2r0 _1viagsn5b _1viagsngj _1viagsn4 hb8hjj1" aria-hidden="true">
															<div class="_1ungv2r0 _1viagsn5v iz7cpl0 iz7cpl2"></div>
														</div>
													</div>
													<div class="_1ungv2r0 _1viagsnr _1viagsnp _1viagsnb7"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7">PM Shift</span></div>
												</li>
												<li class="_1ungv2r0 _1viagsn5b">
													<div class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7">
														<div class="_1ungv2r0 _1viagsn5b _1viagsngj _1viagsn4 hb8hjj1" aria-hidden="true">
															<div class="_1ungv2r0 _1viagsn5v iz7cpl0 iz7cpl2"></div>
														</div>
													</div>
													<div class="_1ungv2r0 _1viagsnr _1viagsnp _1viagsnb7"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7">Immediate Start</span></div>
												</li>
												<li class="_1ungv2r0 _1viagsn5b">
													<div class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7">
														<div class="_1ungv2r0 _1viagsn5b _1viagsngj _1viagsn4 hb8hjj1" aria-hidden="true">
															<div class="_1ungv2r0 _1viagsn5v iz7cpl0 iz7cpl2"></div>
														</div>
													</div>
													<div class="_1ungv2r0 _1viagsnr _1viagsnp _1viagsnb7"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7">Competitive Pay</span></div>
												</li>
											</ul><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av22 _1708b944 _3h66av7" data-testid="job-card-teaser" data-automation="jobShortDescription">Exciting opportunity to join a global freight forwarder in their operations.</span>
											<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6v"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av22 _1708b944 _3h66av7" data-automation="jobListingDate">2d ago</span></div>
										</div>
										</article>
										</div>
										<div class="_1ungv2r0" data-search-sol-meta="{&quot;searchRequestToken&quot;:&quot;57ffa085-d70e-4a32-8a9f-976c323d9f9b&quot;,&quot;token&quot;:&quot;0~57ffa085-d70e-4a32-8a9f-976c323d9f9b&quot;,&quot;jobId&quot;:&quot;80332246&quot;,&quot;section&quot;:&quot;MAIN&quot;,&quot;sectionRank&quot;:2,&quot;jobAdType&quot;:&quot;ORGANIC&quot;,&quot;tags&quot;:{&quot;mordor__flights&quot;:&quot;mordor_530&quot;,&quot;mordor__s&quot;:&quot;0&quot;,&quot;seek:chalice:experiments:remote_search_filter&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:behavioural_cues_filters&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:secondary_filters&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:dynamic_pills&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:serp_job_card_info_density_1&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:serp_google_one_tap&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:matched_qualities&quot;:&quot;2&quot;}}">
											<article class="_1ungv2r0 _1ungv2r1 _1viagsn8n _1viagsn8o _1viagsn7j _1viagsn7k _1viagsnav _1viagsnaw _1viagsn9r _1viagsn9s _1viagsnh _1viagsn67 _1viagsn5f _1st1fyjb _1st1fyj9 _1st1fyja _3h66av18 _3h66av1b _1viagsn33 _1viagsn36" data-automation="normalJob" data-testid="job-card" data-job-id="80332246" data-card-type="JobCard" id="jobcard-2" aria-label="Officer Warehouse" aria-selected="false">
												<div class="_1ungv2r0 _1viagsn4z _1viagsn4x">
													<a href="javascript:void(0);" ddd="/job/80332246?type=standout&amp;ref=search-standalone#sol=260c277608a68cf81b330523d4c5c82fb42f230e" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _1viagsn5j _1viagsnj _1viagsnk _1viagsnl _1viagsnm _1viagsn7" data-automation="job-list-view-job-link" target="_blank"></a>
												</div>
												<div class="_1ungv2r0 _1viagsn4v _1viagsn51">
													<a href="javascript:void(0);" ddd="/job/80332246?type=standout&amp;ref=search-standalone&amp;origin=jobCard#sol=260c277608a68cf81b330523d4c5c82fb42f230e" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _1viagsn5j _1viagsnj _1viagsnk _1viagsnl _1viagsnm _1viagsn7 _1st1fyj4" data-automation="job-list-item-link-overlay" data-testid="job-list-item-link-overlay" data-run-click-only="true" target="_self"></a>
												</div>
												<div class="_1ungv2r0 _1viagsn5j _1viagsn0 _17tnjda0">Listed two hours ago</div>
												<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6v">
													<div class="_1ungv2r0 _1viagsn5b _1viagsnh7 _1viagsngr _157hsn62d">
														<div class="_1ungv2r0 _1viagsn4z _1viagsnr _1viagsnp _1viagsni3 _1viagsnb7">
															<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6n">
																<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6r">
																	<div class="_1ungv2r0 khwnjl1" data-testid="company-logo-container" data-automation="company-logo-container">
																		<div class="_1ungv2r0 _1viagsn5b _1viagsnn" data-automation="company-logo"><img class="khwnjl0" src="https://bx-branding-gateway.cloud.seek.com.au/10bea707-f167-443f-8b09-11e7a8b9660f.1/serpLogo" loading="lazy"></div>
																	</div>
																</div>
																<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6r">
																	<div class="_1ungv2r0">
																		<h3 class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av3 _3h66av21 _1708b944 _3h66ava"><div class="_1ungv2r0 _1viagsn5h _1viagsn53"><a href="javascript:void(0);" ddd="/job/80332246?type=standout&amp;ref=search-standalone&amp;origin=cardTitle#sol=260c277608a68cf81b330523d4c5c82fb42f230e" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _1st1fyje _1st1fyjg" id="job-title-80332246" data-automation="jobTitle" data-testid="job-card-title" data-run-click-only="true" target="_self">Officer Warehouse</a></div></h3>
																		<div class="_1ungv2r0 _1viagsn7v"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66ava"><div class="_1ungv2r0 _1viagsn5h _1viagsn53"><span class="_1ungv2r0 _1viagsn5j _1viagsn0 _17tnjda0">at<!-- --> </span><a href="javascript:void(0);" ddd="/Rio-Tinto-jobs" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _8x0b970 _8x0b971" aria-label="Jobs at Rio Tinto" title="Jobs at Rio Tinto" data-type="company" data-automation="jobCompany" target="_self">Rio Tinto</a></div>
																		</span>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div class="_1ungv2r0 _1viagsn4z _1viagsnr _1viagsnhv _1viagsnhz _1viagsnb7">
														<div class="_1ungv2r0 _1viagsn5h _1viagsn53">
															<div class="_1ungv2r0 _157hsn60 _157hsn64 _157hsn61 _157hsn618 _157hsn62c _157hsn63g">
																<div class="_1ungv2r0 _1viagsn5f"><button class="_1ungv2r0 _1ungv2r7 _1viagsn8v _1viagsn7r _1viagsnb3 _1viagsn9z _1viagsn7 _1viagsnw _1viagsn5v _1viagsn5f _1viagsn4z _1viagsnz _1viagsny _1viagsn5 _1viagsnib _1viagsn4 _1viagsnh _4z9l0z0 _4z9l0z6 _12i37nn0 uf4ir0" type="button" id="serp-signed-out-save-job-80332246" aria-label="Sign in to save this job" tabindex="0" data-testid="signed-out-save-job" data-automation="signed-out-save-job"><span class="_1ungv2r0 _1viagsnj _1viagsnk _1viagsnl _1viagsnm _1viagsn5j _1viagsni _1viagsn5v _1viagsnx _1viagsn6 di8fio0 _4z9l0z4 _1viagsn4t _1viagsn4u"></span><span class="_1ungv2r0 _1viagsnj _1viagsnk _1viagsnl _1viagsnm _1viagsn5j _1viagsni _1viagsn5v _1viagsnx _1viagsn6 _4z9l0z3 _4z9l0zi _3h66av18 _3h66av1a _1viagsn2t _1viagsn2u"></span><span class="_1ungv2r0 _1viagsnj _1viagsnk _1viagsnl _1viagsnm _1viagsn5j _1viagsni _1viagsn5v _1viagsnx _1viagsn6 _4z9l0z2 _4z9l0zj _3h66av18 _3h66av1a _1viagsn2r _1viagsn2s"></span><span class="_1ungv2r0 _1viagsn4z _1viagsn5f qa05hu0 _3h66avi qa05hu1"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" xml:space="preserve" focusable="false" fill="currentColor" width="16" height="16" class="_1ungv2r0 _1viagsnp _1viagsnn _1viagsn4z _3h66av21" aria-hidden="true"><path d="M19 5.1c.1-1.6-1.1-2.9-2.7-3.1H7.6C6.1 2.1 4.9 3.5 5 5v16c0 .4.2.7.5.9.3.2.7.2 1 0l5.4-3.6 5.4 3.6c.2.1.4.2.6.2.2 0 .3 0 .5-.1.3-.2.5-.5.5-.9l.1-16zm-2 14-4.4-3c-.3-.2-.8-.2-1.1 0l-4.4 3L7 4.9c0-.4.3-.9.7-.9h8.5c.5 0 .8.5.8 1v14.1z"></path></svg></span></button></div>
															</div>
														</div>
													</div>
												</div>
												<div class="_1ungv2r0 _1viagsn5j _1viagsn0 _17tnjda0">
													<p class="_1ungv2r0">This is a Full time job</p>
												</div>
												<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6n">
													<div class="_1ungv2r0"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7"><span class="_1ungv2r0" data-automation="jobCardLocation"><a href="javascript:void(0);" ddd="/jobs/in-Evans-Landing-QLD-4874?classification=6205%2C6092" rel="nofollow" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _8x0b970 _8x0b972" aria-label="Limit results to Evans Landing" tabindex="-1" title="Limit results to Evans Landing" data-type="location" data-automation="jobLocation" target="_self">Evans Landing</a></span><span class="_1ungv2r0" data-automation="jobCardLocation">, <a href="javascript:void(0);" ddd="/jobs/in-Cairns-&amp;-Far-North-QLD?classification=6205%2C6092" rel="nofollow" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _8x0b970 _8x0b972" aria-label="Limit results to Cairns &amp; Far North QLD" tabindex="-1" title="Limit results to Cairns &amp; Far North QLD" data-type="location" data-automation="jobLocation" target="_self">Cairns &amp; Far North QLD</a></span></span>
													</div>
													<div class="_1ungv2r0 _1viagsn4v _1viagsn50"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7"><div class="_1ungv2r0 _1viagsn5b _1viagsnh7 _1viagsngf _1viagsn7f _1viagsnhn _1suz4l60"><span class="_1ungv2r0 _1viagsn5j _1viagsn0 _17tnjda0">subClassification: Other</span><a href="javascript:void(0);" ddd="/jobs-in-manufacturing-transport-logistics/other" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _8x0b970 _8x0b972" aria-label="Limit results to Other in Manufacturing, Transport &amp; Logistics" tabindex="-1" title="Limit results to Other in Manufacturing, Transport &amp; Logistics" data-type="subClassification" data-automation="jobSubClassification" target="_self">Other</a>
														<div class="_1ungv2r0 _1viagsnff"></div><span class="_1ungv2r0 _1viagsn5j _1viagsn0 _17tnjda0">classification: Manufacturing, Transport &amp; Logistics</span><a href="javascript:void(0);" ddd="/jobs-in-manufacturing-transport-logistics" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _8x0b970 _8x0b972" aria-label="Limit results to Manufacturing, Transport &amp; Logistics" tabindex="-1" title="Limit results to Manufacturing, Transport &amp; Logistics" data-type="classification" data-automation="jobClassification" target="_self">(Manufacturing, Transport &amp; Logistics)</a></div>
													</span>
												</div>
										</div><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av22 _1708b944 _3h66av7" data-testid="job-card-teaser" data-automation="jobShortDescription">Officer Warehouse</span>
										<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6v"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av22 _1708b944 _3h66av7" data-automation="jobListingDate">2h ago</span>
											<div class="_1ungv2r0"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av3 _3h66av1w _1708b944 _3h66av7"><span class="_1ungv2r0 _1viagsn5b"><span class="_1ungv2r0 _1viagsn4z _1viagsn9v _1viagsnhz _1viagsnhv _1viagsnr"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="currentColor" class="_1viagsn57 _1viagsn5f qa05hu0 qa05hu2 qa05hu3 qa05hu4" aria-hidden="true"><path fill-rule="evenodd" clip-rule="evenodd" d="M1 6C1 3.22727 3.22727 1 6 1C8.77273 1 11 3.22727 11 6C11 8.77273 8.77273 11 6 11C3.22727 11 1 8.77273 1 6ZM6.45455 5.72727L8 6.5C8.22727 6.63636 8.31818 6.90909 8.22727 7.09091C8.13636 7.27273 8 7.36364 7.81818 7.36364C7.72727 7.36364 7.68182 7.36364 7.63636 7.31818L5.81818 6.40909C5.63636 6.31818 5.54545 6.18182 5.54545 6V3.27273C5.54545 3 5.72727 2.81818 6 2.81818C6.27273 2.81818 6.45455 3 6.45455 3.27273V5.72727Z"></path></svg></span><span class="_1ungv2r0 _1viagsn4z _1viagsnr">Be an early applicant</span></span>
												</span>
											</div>
										</div>
										</div>
										</article>
										</div>
										<div class="_1ungv2r0" data-search-sol-meta="{&quot;searchRequestToken&quot;:&quot;57ffa085-d70e-4a32-8a9f-976c323d9f9b&quot;,&quot;token&quot;:&quot;0~57ffa085-d70e-4a32-8a9f-976c323d9f9b&quot;,&quot;jobId&quot;:&quot;80125816&quot;,&quot;section&quot;:&quot;MAIN&quot;,&quot;sectionRank&quot;:3,&quot;jobAdType&quot;:&quot;ORGANIC&quot;,&quot;tags&quot;:{&quot;mordor__flights&quot;:&quot;mordor_530&quot;,&quot;mordor__s&quot;:&quot;0&quot;,&quot;seek:chalice:experiments:remote_search_filter&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:behavioural_cues_filters&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:secondary_filters&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:dynamic_pills&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:serp_job_card_info_density_1&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:serp_google_one_tap&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:matched_qualities&quot;:&quot;2&quot;}}">
											<article class="_1ungv2r0 _1ungv2r1 _1viagsn8n _1viagsn8o _1viagsn7j _1viagsn7k _1viagsnav _1viagsnaw _1viagsn9r _1viagsn9s _1viagsnh _1viagsn67 _1viagsn5f _1st1fyjb _1st1fyj9 _1st1fyja _3h66av18 _3h66av1b _1viagsn33 _1viagsn36" data-automation="normalJob" data-testid="job-card" data-job-id="80125816" data-card-type="JobCard" id="jobcard-3" aria-label="Freight Handlers / Parcel Sorters Wanted Granville Location ASAP STARTS" aria-selected="false">
												<div class="_1ungv2r0 _1viagsn4z _1viagsn4x">
													<a href="javascript:void(0);" ddd="/job/80125816?type=standout&amp;ref=search-standalone#sol=6776d6cc954bf01c213d9728a003637c59339dff" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _1viagsn5j _1viagsnj _1viagsnk _1viagsnl _1viagsnm _1viagsn7" data-automation="job-list-view-job-link" target="_blank"></a>
												</div>
												<div class="_1ungv2r0 _1viagsn4v _1viagsn51">
													<a href="javascript:void(0);" ddd="/job/80125816?type=standout&amp;ref=search-standalone&amp;origin=jobCard#sol=6776d6cc954bf01c213d9728a003637c59339dff" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _1viagsn5j _1viagsnj _1viagsnk _1viagsnl _1viagsnm _1viagsn7 _1st1fyj4" data-automation="job-list-item-link-overlay" data-testid="job-list-item-link-overlay" data-run-click-only="true" target="_self"></a>
												</div>
												<div class="_1ungv2r0 _1viagsn5j _1viagsn0 _17tnjda0">Listed nine days ago</div>
												<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6v">
													<div class="_1ungv2r0 _1viagsn5b _1viagsnh7 _1viagsngr _157hsn62d">
														<div class="_1ungv2r0 _1viagsn4z _1viagsnr _1viagsnp _1viagsni3 _1viagsnb7">
															<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6n">
																<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6r">
																	<div class="_1ungv2r0 khwnjl1" data-testid="company-logo-container" data-automation="company-logo-container">
																		<div class="_1ungv2r0 _1viagsn5b _1viagsnn" data-automation="company-logo"><img class="khwnjl0" src="https://bx-branding-gateway.cloud.seek.com.au/e927d0ef-e23a-2f20-fdfe-f227b9f1d20b.1/serpLogo" loading="lazy"></div>
																	</div>
																</div>
																<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6r">
																	<div class="_1ungv2r0">
																		<h3 class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av3 _3h66av21 _1708b944 _3h66ava"><div class="_1ungv2r0 _1viagsn5h _1viagsn53"><a href="javascript:void(0);" ddd="/job/80125816?type=standout&amp;ref=search-standalone&amp;origin=cardTitle#sol=6776d6cc954bf01c213d9728a003637c59339dff" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _1st1fyje _1st1fyjg" id="job-title-80125816" data-automation="jobTitle" data-testid="job-card-title" data-run-click-only="true" target="_self">Freight Handlers / Parcel Sorters Wanted Granville Location ASAP STARTS</a></div></h3>
																		<div class="_1ungv2r0 _1viagsn7v"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66ava"><div class="_1ungv2r0 _1viagsn5h _1viagsn53"><span class="_1ungv2r0 _1viagsn5j _1viagsn0 _17tnjda0">at<!-- --> </span><a href="javascript:void(0);" ddd="/Labourforce-jobs" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _8x0b970 _8x0b971" aria-label="Jobs at Labourforce" title="Jobs at Labourforce" data-type="company" data-automation="jobCompany" target="_self">Labourforce</a></div>
																		</span>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div class="_1ungv2r0 _1viagsn4z _1viagsnr _1viagsnhv _1viagsnhz _1viagsnb7">
														<div class="_1ungv2r0 _1viagsn5h _1viagsn53">
															<div class="_1ungv2r0 _157hsn60 _157hsn64 _157hsn61 _157hsn618 _157hsn62c _157hsn63g">
																<div class="_1ungv2r0 _1viagsn5f"><button class="_1ungv2r0 _1ungv2r7 _1viagsn8v _1viagsn7r _1viagsnb3 _1viagsn9z _1viagsn7 _1viagsnw _1viagsn5v _1viagsn5f _1viagsn4z _1viagsnz _1viagsny _1viagsn5 _1viagsnib _1viagsn4 _1viagsnh _4z9l0z0 _4z9l0z6 _12i37nn0 uf4ir0" type="button" id="serp-signed-out-save-job-80125816" aria-label="Sign in to save this job" tabindex="0" data-testid="signed-out-save-job" data-automation="signed-out-save-job"><span class="_1ungv2r0 _1viagsnj _1viagsnk _1viagsnl _1viagsnm _1viagsn5j _1viagsni _1viagsn5v _1viagsnx _1viagsn6 di8fio0 _4z9l0z4 _1viagsn4t _1viagsn4u"></span><span class="_1ungv2r0 _1viagsnj _1viagsnk _1viagsnl _1viagsnm _1viagsn5j _1viagsni _1viagsn5v _1viagsnx _1viagsn6 _4z9l0z3 _4z9l0zi _3h66av18 _3h66av1a _1viagsn2t _1viagsn2u"></span><span class="_1ungv2r0 _1viagsnj _1viagsnk _1viagsnl _1viagsnm _1viagsn5j _1viagsni _1viagsn5v _1viagsnx _1viagsn6 _4z9l0z2 _4z9l0zj _3h66av18 _3h66av1a _1viagsn2r _1viagsn2s"></span><span class="_1ungv2r0 _1viagsn4z _1viagsn5f qa05hu0 _3h66avi qa05hu1"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" xml:space="preserve" focusable="false" fill="currentColor" width="16" height="16" class="_1ungv2r0 _1viagsnp _1viagsnn _1viagsn4z _3h66av21" aria-hidden="true"><path d="M19 5.1c.1-1.6-1.1-2.9-2.7-3.1H7.6C6.1 2.1 4.9 3.5 5 5v16c0 .4.2.7.5.9.3.2.7.2 1 0l5.4-3.6 5.4 3.6c.2.1.4.2.6.2.2 0 .3 0 .5-.1.3-.2.5-.5.5-.9l.1-16zm-2 14-4.4-3c-.3-.2-.8-.2-1.1 0l-4.4 3L7 4.9c0-.4.3-.9.7-.9h8.5c.5 0 .8.5.8 1v14.1z"></path></svg></span></button></div>
															</div>
														</div>
													</div>
												</div>
												<div class="_1ungv2r0 _1viagsn5j _1viagsn0 _17tnjda0">
													<p class="_1ungv2r0">This is a Contract/Temp job</p>
												</div>
												<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6n">
													<div class="_1ungv2r0"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7"><span class="_1ungv2r0" data-automation="jobCardLocation"><a href="javascript:void(0);" ddd="/jobs/in-South-Granville-NSW-2142?classification=6205%2C6092" rel="nofollow" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _8x0b970 _8x0b972" aria-label="Limit results to South Granville" tabindex="-1" title="Limit results to South Granville" data-type="location" data-automation="jobLocation" target="_self">South Granville</a></span><span class="_1ungv2r0" data-automation="jobCardLocation">, <a href="javascript:void(0);" ddd="/jobs/in-All-Sydney-NSW?classification=6205%2C6092" rel="nofollow" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _8x0b970 _8x0b972" aria-label="Limit results to Sydney NSW" tabindex="-1" title="Limit results to Sydney NSW" data-type="location" data-automation="jobLocation" target="_self">Sydney NSW</a></span></span>
													</div>
													<div class="_1ungv2r0" aria-label="Salary: $40 per hour + shift Allowance + OT "><span class="_1ungv2r0 _1viagsn4z _1viagsnr _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7 ndjyux0" data-automation="jobSalary"><span class="_1ungv2r0 ndjyux2 _1viagsn4z _1viagsn0 _1viagsnr ndjyux4" style="--ndjyux3:2">$40 per hour + shift Allowance + OT </span></span>
													</div>
													<div class="_1ungv2r0 _1viagsn4v _1viagsn50"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7"><div class="_1ungv2r0 _1viagsn5b _1viagsnh7 _1viagsngf _1viagsn7f _1viagsnhn _1suz4l60"><span class="_1ungv2r0 _1viagsn5j _1viagsn0 _17tnjda0">subClassification: Warehousing, Storage &amp; Distribution</span><a href="javascript:void(0);" ddd="/jobs-in-manufacturing-transport-logistics/warehousing-storage-distribution" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _8x0b970 _8x0b972" aria-label="Limit results to Warehousing, Storage &amp; Distribution in Manufacturing, Transport &amp; Logistics" tabindex="-1" title="Limit results to Warehousing, Storage &amp; Distribution in Manufacturing, Transport &amp; Logistics" data-type="subClassification" data-automation="jobSubClassification" target="_self">Warehousing, Storage &amp; Distribution</a>
														<div class="_1ungv2r0 _1viagsnff"></div><span class="_1ungv2r0 _1viagsn5j _1viagsn0 _17tnjda0">classification: Manufacturing, Transport &amp; Logistics</span><a href="javascript:void(0);" ddd="/jobs-in-manufacturing-transport-logistics" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _8x0b970 _8x0b972" aria-label="Limit results to Manufacturing, Transport &amp; Logistics" tabindex="-1" title="Limit results to Manufacturing, Transport &amp; Logistics" data-type="classification" data-automation="jobClassification" target="_self">(Manufacturing, Transport &amp; Logistics)</a></div>
													</span>
												</div>
										</div>
										<ul class="_1ungv2r0 _1ungv2r3 _1viagsn5b _1viagsnhf _1viagsn6n _1viagsni7">
											<li class="_1ungv2r0 _1viagsn5b">
												<div class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7">
													<div class="_1ungv2r0 _1viagsn5b _1viagsngj _1viagsn4 hb8hjj1" aria-hidden="true">
														<div class="_1ungv2r0 _1viagsn5v iz7cpl0 iz7cpl2"></div>
													</div>
												</div>
												<div class="_1ungv2r0 _1viagsnr _1viagsnp _1viagsnb7"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7">Granville Location</span></div>
											</li>
											<li class="_1ungv2r0 _1viagsn5b">
												<div class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7">
													<div class="_1ungv2r0 _1viagsn5b _1viagsngj _1viagsn4 hb8hjj1" aria-hidden="true">
														<div class="_1ungv2r0 _1viagsn5v iz7cpl0 iz7cpl2"></div>
													</div>
												</div>
												<div class="_1ungv2r0 _1viagsnr _1viagsnp _1viagsnb7"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7">Good $$$</span></div>
											</li>
											<li class="_1ungv2r0 _1viagsn5b">
												<div class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7">
													<div class="_1ungv2r0 _1viagsn5b _1viagsngj _1viagsn4 hb8hjj1" aria-hidden="true">
														<div class="_1ungv2r0 _1viagsn5v iz7cpl0 iz7cpl2"></div>
													</div>
												</div>
												<div class="_1ungv2r0 _1viagsnr _1viagsnp _1viagsnb7"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7">ASAP start </span></div>
											</li>
										</ul><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av22 _1708b944 _3h66av7" data-testid="job-card-teaser" data-automation="jobShortDescription">Our client, a leading logistics provider dedicated to delivering exceptional service and efficiency who thrives on ensuring that packages and freig...</span>
										<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6v"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av22 _1708b944 _3h66av7" data-automation="jobListingDate">9d ago</span></div>
										</div>
										</article>
										</div>
										<div class="_1ungv2r0" data-search-sol-meta="{&quot;searchRequestToken&quot;:&quot;57ffa085-d70e-4a32-8a9f-976c323d9f9b&quot;,&quot;token&quot;:&quot;0~57ffa085-d70e-4a32-8a9f-976c323d9f9b&quot;,&quot;jobId&quot;:&quot;80076570&quot;,&quot;section&quot;:&quot;MAIN&quot;,&quot;sectionRank&quot;:4,&quot;jobAdType&quot;:&quot;ORGANIC&quot;,&quot;tags&quot;:{&quot;mordor__flights&quot;:&quot;mordor_530&quot;,&quot;mordor__s&quot;:&quot;0&quot;,&quot;seek:chalice:experiments:remote_search_filter&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:behavioural_cues_filters&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:secondary_filters&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:dynamic_pills&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:serp_job_card_info_density_1&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:serp_google_one_tap&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:matched_qualities&quot;:&quot;2&quot;}}">
											<article class="_1ungv2r0 _1ungv2r1 _1viagsn8n _1viagsn8o _1viagsn7j _1viagsn7k _1viagsnav _1viagsnaw _1viagsn9r _1viagsn9s _1viagsnh _1viagsn67 _1viagsn5f _1st1fyjb _1st1fyj9 _1st1fyja _3h66av18 _3h66av1b _1viagsn33 _1viagsn36" data-automation="normalJob" data-testid="job-card" data-job-id="80076570" data-card-type="JobCard" id="jobcard-4" aria-label="Pick Packers for Night Shift - $39+ an hour." aria-selected="false">
												<div class="_1ungv2r0 _1viagsn4z _1viagsn4x">
													<a href="javascript:void(0);" ddd="/job/80076570?type=standout&amp;ref=search-standalone#sol=119d9fe8d18811cdfe94e88aa30ad0f7d9c1b83e" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _1viagsn5j _1viagsnj _1viagsnk _1viagsnl _1viagsnm _1viagsn7" data-automation="job-list-view-job-link" target="_blank"></a>
												</div>
												<div class="_1ungv2r0 _1viagsn4v _1viagsn51">
													<a href="javascript:void(0);" ddd="/job/80076570?type=standout&amp;ref=search-standalone&amp;origin=jobCard#sol=119d9fe8d18811cdfe94e88aa30ad0f7d9c1b83e" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _1viagsn5j _1viagsnj _1viagsnk _1viagsnl _1viagsnm _1viagsn7 _1st1fyj4" data-automation="job-list-item-link-overlay" data-testid="job-list-item-link-overlay" data-run-click-only="true" target="_self"></a>
												</div>
												<div class="_1ungv2r0 _1viagsn5j _1viagsn0 _17tnjda0">Listed twelve days ago</div>
												<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6v">
													<div class="_1ungv2r0 _1viagsn5b _1viagsnh7 _1viagsngr _157hsn62d">
														<div class="_1ungv2r0 _1viagsn4z _1viagsnr _1viagsnp _1viagsni3 _1viagsnb7">
															<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6n">
																<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6r">
																	<div class="_1ungv2r0 khwnjl1" data-testid="company-logo-container" data-automation="company-logo-container">
																		<div class="_1ungv2r0 _1viagsn5b _1viagsnn" data-automation="company-logo"><img class="khwnjl0" src="https://bx-branding-gateway.cloud.seek.com.au/1adae3c1-ec9a-7694-19ce-e480f5669961.1/serpLogo" loading="lazy"></div>
																	</div>
																</div>
																<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6r">
																	<div class="_1ungv2r0">
																		<h3 class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av3 _3h66av21 _1708b944 _3h66ava"><div class="_1ungv2r0 _1viagsn5h _1viagsn53"><a href="javascript:void(0);" ddd="/job/80076570?type=standout&amp;ref=search-standalone&amp;origin=cardTitle#sol=119d9fe8d18811cdfe94e88aa30ad0f7d9c1b83e" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _1st1fyje _1st1fyjg" id="job-title-80076570" data-automation="jobTitle" data-testid="job-card-title" data-run-click-only="true" target="_self">Pick Packers for Night Shift - $39+ an hour.</a></div></h3>
																		<div class="_1ungv2r0 _1viagsn7v"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66ava"><div class="_1ungv2r0 _1viagsn5h _1viagsn53"><span class="_1ungv2r0 _1viagsn5j _1viagsn0 _17tnjda0">at<!-- --> </span><a href="javascript:void(0);" ddd="/ASAP-Recruitment-jobs" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _8x0b970 _8x0b971" aria-label="Jobs at ASAP Recruitment" title="Jobs at ASAP Recruitment" data-type="company" data-automation="jobCompany" target="_self">asap recruitment</a></div>
																		</span>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div class="_1ungv2r0 _1viagsn4z _1viagsnr _1viagsnhv _1viagsnhz _1viagsnb7">
														<div class="_1ungv2r0 _1viagsn5h _1viagsn53">
															<div class="_1ungv2r0 _157hsn60 _157hsn64 _157hsn61 _157hsn618 _157hsn62c _157hsn63g">
																<div class="_1ungv2r0 _1viagsn5f"><button class="_1ungv2r0 _1ungv2r7 _1viagsn8v _1viagsn7r _1viagsnb3 _1viagsn9z _1viagsn7 _1viagsnw _1viagsn5v _1viagsn5f _1viagsn4z _1viagsnz _1viagsny _1viagsn5 _1viagsnib _1viagsn4 _1viagsnh _4z9l0z0 _4z9l0z6 _12i37nn0 uf4ir0" type="button" id="serp-signed-out-save-job-80076570" aria-label="Sign in to save this job" tabindex="0" data-testid="signed-out-save-job" data-automation="signed-out-save-job"><span class="_1ungv2r0 _1viagsnj _1viagsnk _1viagsnl _1viagsnm _1viagsn5j _1viagsni _1viagsn5v _1viagsnx _1viagsn6 di8fio0 _4z9l0z4 _1viagsn4t _1viagsn4u"></span><span class="_1ungv2r0 _1viagsnj _1viagsnk _1viagsnl _1viagsnm _1viagsn5j _1viagsni _1viagsn5v _1viagsnx _1viagsn6 _4z9l0z3 _4z9l0zi _3h66av18 _3h66av1a _1viagsn2t _1viagsn2u"></span><span class="_1ungv2r0 _1viagsnj _1viagsnk _1viagsnl _1viagsnm _1viagsn5j _1viagsni _1viagsn5v _1viagsnx _1viagsn6 _4z9l0z2 _4z9l0zj _3h66av18 _3h66av1a _1viagsn2r _1viagsn2s"></span><span class="_1ungv2r0 _1viagsn4z _1viagsn5f qa05hu0 _3h66avi qa05hu1"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" xml:space="preserve" focusable="false" fill="currentColor" width="16" height="16" class="_1ungv2r0 _1viagsnp _1viagsnn _1viagsn4z _3h66av21" aria-hidden="true"><path d="M19 5.1c.1-1.6-1.1-2.9-2.7-3.1H7.6C6.1 2.1 4.9 3.5 5 5v16c0 .4.2.7.5.9.3.2.7.2 1 0l5.4-3.6 5.4 3.6c.2.1.4.2.6.2.2 0 .3 0 .5-.1.3-.2.5-.5.5-.9l.1-16zm-2 14-4.4-3c-.3-.2-.8-.2-1.1 0l-4.4 3L7 4.9c0-.4.3-.9.7-.9h8.5c.5 0 .8.5.8 1v14.1z"></path></svg></span></button></div>
															</div>
														</div>
													</div>
												</div>
												<div class="_1ungv2r0 _1viagsn5j _1viagsn0 _17tnjda0">
													<p class="_1ungv2r0">This is a Casual/Vacation job</p>
												</div>
												<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6n">
													<div class="_1ungv2r0"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7"><span class="_1ungv2r0" data-automation="jobCardLocation"><a href="javascript:void(0);" ddd="/jobs/in-Berrinba-QLD-4117?classification=6205%2C6092" rel="nofollow" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _8x0b970 _8x0b972" aria-label="Limit results to Berrinba" tabindex="-1" title="Limit results to Berrinba" data-type="location" data-automation="jobLocation" target="_self">Berrinba</a></span><span class="_1ungv2r0" data-automation="jobCardLocation">, <a href="javascript:void(0);" ddd="/jobs/in-All-Brisbane-QLD?classification=6205%2C6092" rel="nofollow" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _8x0b970 _8x0b972" aria-label="Limit results to Brisbane QLD" tabindex="-1" title="Limit results to Brisbane QLD" data-type="location" data-automation="jobLocation" target="_self">Brisbane QLD</a></span></span>
													</div>
													<div class="_1ungv2r0" aria-label="Salary: $35 - $39.99 per hour"><span class="_1ungv2r0 _1viagsn4z _1viagsnr _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7 ndjyux0" data-automation="jobSalary"><span class="_1ungv2r0 ndjyux2 _1viagsn4z _1viagsn0 _1viagsnr ndjyux4" style="--ndjyux3:2">$35 - $39.99 per hour</span></span>
													</div>
													<div class="_1ungv2r0 _1viagsn4v _1viagsn50"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7"><div class="_1ungv2r0 _1viagsn5b _1viagsnh7 _1viagsngf _1viagsn7f _1viagsnhn _1suz4l60"><span class="_1ungv2r0 _1viagsn5j _1viagsn0 _17tnjda0">subClassification: Warehousing, Storage &amp; Distribution</span><a href="javascript:void(0);" ddd="/jobs-in-manufacturing-transport-logistics/warehousing-storage-distribution" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _8x0b970 _8x0b972" aria-label="Limit results to Warehousing, Storage &amp; Distribution in Manufacturing, Transport &amp; Logistics" tabindex="-1" title="Limit results to Warehousing, Storage &amp; Distribution in Manufacturing, Transport &amp; Logistics" data-type="subClassification" data-automation="jobSubClassification" target="_self">Warehousing, Storage &amp; Distribution</a>
														<div class="_1ungv2r0 _1viagsnff"></div><span class="_1ungv2r0 _1viagsn5j _1viagsn0 _17tnjda0">classification: Manufacturing, Transport &amp; Logistics</span><a href="javascript:void(0);" ddd="/jobs-in-manufacturing-transport-logistics" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _8x0b970 _8x0b972" aria-label="Limit results to Manufacturing, Transport &amp; Logistics" tabindex="-1" title="Limit results to Manufacturing, Transport &amp; Logistics" data-type="classification" data-automation="jobClassification" target="_self">(Manufacturing, Transport &amp; Logistics)</a></div>
													</span>
												</div>
										</div>
										<ul class="_1ungv2r0 _1ungv2r3 _1viagsn5b _1viagsnhf _1viagsn6n _1viagsni7">
											<li class="_1ungv2r0 _1viagsn5b">
												<div class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7">
													<div class="_1ungv2r0 _1viagsn5b _1viagsngj _1viagsn4 hb8hjj1" aria-hidden="true">
														<div class="_1ungv2r0 _1viagsn5v iz7cpl0 iz7cpl2"></div>
													</div>
												</div>
												<div class="_1ungv2r0 _1viagsnr _1viagsnp _1viagsnb7"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7">Berrinba Location</span></div>
											</li>
											<li class="_1ungv2r0 _1viagsn5b">
												<div class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7">
													<div class="_1ungv2r0 _1viagsn5b _1viagsngj _1viagsn4 hb8hjj1" aria-hidden="true">
														<div class="_1ungv2r0 _1viagsn5v iz7cpl0 iz7cpl2"></div>
													</div>
												</div>
												<div class="_1ungv2r0 _1viagsnr _1viagsnp _1viagsnb7"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7">Temp to Perm Opportunites</span></div>
											</li>
											<li class="_1ungv2r0 _1viagsn5b">
												<div class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7">
													<div class="_1ungv2r0 _1viagsn5b _1viagsngj _1viagsn4 hb8hjj1" aria-hidden="true">
														<div class="_1ungv2r0 _1viagsn5v iz7cpl0 iz7cpl2"></div>
													</div>
												</div>
												<div class="_1ungv2r0 _1viagsnr _1viagsnp _1viagsnb7"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7">Great Rates</span></div>
											</li>
										</ul><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av22 _1708b944 _3h66av7" data-testid="job-card-teaser" data-automation="jobShortDescription">Are you a Pick Packer looking for Night Shift work? Immediate start and great pay rates!</span>
										<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6v"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av22 _1708b944 _3h66av7" data-automation="jobListingDate">12d ago</span></div>
										</div>
										</article>
										</div>
										<div class="_1ungv2r0" data-search-sol-meta="{&quot;searchRequestToken&quot;:&quot;57ffa085-d70e-4a32-8a9f-976c323d9f9b&quot;,&quot;token&quot;:&quot;0~57ffa085-d70e-4a32-8a9f-976c323d9f9b&quot;,&quot;jobId&quot;:&quot;80326132&quot;,&quot;section&quot;:&quot;MAIN&quot;,&quot;sectionRank&quot;:5,&quot;jobAdType&quot;:&quot;ORGANIC&quot;,&quot;tags&quot;:{&quot;mordor__flights&quot;:&quot;mordor_530&quot;,&quot;mordor__s&quot;:&quot;0&quot;,&quot;seek:chalice:experiments:remote_search_filter&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:behavioural_cues_filters&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:secondary_filters&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:dynamic_pills&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:serp_job_card_info_density_1&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:serp_google_one_tap&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:matched_qualities&quot;:&quot;2&quot;}}">
											<article class="_1ungv2r0 _1ungv2r1 _1viagsn8n _1viagsn8o _1viagsn7j _1viagsn7k _1viagsnav _1viagsnaw _1viagsn9r _1viagsn9s _1viagsnh _1viagsn67 _1viagsn5f _1st1fyjb _1st1fyj9 _1st1fyja _3h66av18 _3h66av1b _1viagsn33 _1viagsn36" data-automation="normalJob" data-testid="job-card" data-job-id="80326132" data-card-type="JobCard" id="jobcard-5" aria-label="Supply Base Operator" aria-selected="false">
												<div class="_1ungv2r0 _1viagsn4z _1viagsn4x">
													<a href="javascript:void(0);" ddd="/job/80326132?type=standard&amp;ref=search-standalone#sol=f2a183b43e8f45481f8782f7db7b678f4ebe63ca" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _1viagsn5j _1viagsnj _1viagsnk _1viagsnl _1viagsnm _1viagsn7" data-automation="job-list-view-job-link" target="_blank"></a>
												</div>
												<div class="_1ungv2r0 _1viagsn4v _1viagsn51">
													<a href="javascript:void(0);" ddd="/job/80326132?type=standard&amp;ref=search-standalone&amp;origin=jobCard#sol=f2a183b43e8f45481f8782f7db7b678f4ebe63ca" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _1viagsn5j _1viagsnj _1viagsnk _1viagsnl _1viagsnm _1viagsn7 _1st1fyj4" data-automation="job-list-item-link-overlay" data-testid="job-list-item-link-overlay" data-run-click-only="true" target="_self"></a>
												</div>
												<div class="_1ungv2r0 _1viagsn5j _1viagsn0 _17tnjda0">Listed six hours ago</div>
												<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6v">
													<div class="_1ungv2r0 _1viagsn5b _1viagsnh7 _1viagsngr _157hsn62d">
														<div class="_1ungv2r0 _1viagsn4z _1viagsnr _1viagsnp _1viagsni3 _1viagsnb7">
															<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6v">
																<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6r">
																	<div class="_1ungv2r0">
																		<h3 class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av3 _3h66av21 _1708b944 _3h66ava"><div class="_1ungv2r0 _1viagsn5h _1viagsn53"><a href="javascript:void(0);" ddd="/job/80326132?type=standard&amp;ref=search-standalone&amp;origin=cardTitle#sol=f2a183b43e8f45481f8782f7db7b678f4ebe63ca" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _1st1fyje _1st1fyjg" id="job-title-80326132" data-automation="jobTitle" data-testid="job-card-title" data-run-click-only="true" target="_self">Supply Base Operator</a></div></h3>
																		<div class="_1ungv2r0 _1viagsn7v"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66ava"><div class="_1ungv2r0 _1viagsn5h _1viagsn53"><span class="_1ungv2r0 _1viagsn5j _1viagsn0 _17tnjda0">at<!-- --> </span><a href="javascript:void(0);" ddd="/Toll-People-jobs" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _8x0b970 _8x0b971" aria-label="Jobs at Toll People" title="Jobs at Toll People" data-type="company" data-automation="jobCompany" target="_self">Toll People</a></div>
																		</span>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div class="_1ungv2r0 _1viagsn4z _1viagsnr _1viagsnhv _1viagsnhz _1viagsnb7">
														<div class="_1ungv2r0 _1viagsn5h _1viagsn53">
															<div class="_1ungv2r0 _157hsn60 _157hsn64 _157hsn61 _157hsn618 _157hsn62c _157hsn63g">
																<div class="_1ungv2r0 _1viagsn5f"><button class="_1ungv2r0 _1ungv2r7 _1viagsn8v _1viagsn7r _1viagsnb3 _1viagsn9z _1viagsn7 _1viagsnw _1viagsn5v _1viagsn5f _1viagsn4z _1viagsnz _1viagsny _1viagsn5 _1viagsnib _1viagsn4 _1viagsnh _4z9l0z0 _4z9l0z6 _12i37nn0 uf4ir0" type="button" id="serp-signed-out-save-job-80326132" aria-label="Sign in to save this job" tabindex="0" data-testid="signed-out-save-job" data-automation="signed-out-save-job"><span class="_1ungv2r0 _1viagsnj _1viagsnk _1viagsnl _1viagsnm _1viagsn5j _1viagsni _1viagsn5v _1viagsnx _1viagsn6 di8fio0 _4z9l0z4 _1viagsn4t _1viagsn4u"></span><span class="_1ungv2r0 _1viagsnj _1viagsnk _1viagsnl _1viagsnm _1viagsn5j _1viagsni _1viagsn5v _1viagsnx _1viagsn6 _4z9l0z3 _4z9l0zi _3h66av18 _3h66av1a _1viagsn2t _1viagsn2u"></span><span class="_1ungv2r0 _1viagsnj _1viagsnk _1viagsnl _1viagsnm _1viagsn5j _1viagsni _1viagsn5v _1viagsnx _1viagsn6 _4z9l0z2 _4z9l0zj _3h66av18 _3h66av1a _1viagsn2r _1viagsn2s"></span><span class="_1ungv2r0 _1viagsn4z _1viagsn5f qa05hu0 _3h66avi qa05hu1"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" xml:space="preserve" focusable="false" fill="currentColor" width="16" height="16" class="_1ungv2r0 _1viagsnp _1viagsnn _1viagsn4z _3h66av21" aria-hidden="true"><path d="M19 5.1c.1-1.6-1.1-2.9-2.7-3.1H7.6C6.1 2.1 4.9 3.5 5 5v16c0 .4.2.7.5.9.3.2.7.2 1 0l5.4-3.6 5.4 3.6c.2.1.4.2.6.2.2 0 .3 0 .5-.1.3-.2.5-.5.5-.9l.1-16zm-2 14-4.4-3c-.3-.2-.8-.2-1.1 0l-4.4 3L7 4.9c0-.4.3-.9.7-.9h8.5c.5 0 .8.5.8 1v14.1z"></path></svg></span></button></div>
															</div>
														</div>
													</div>
												</div>
												<div class="_1ungv2r0 _1viagsn5j _1viagsn0 _17tnjda0">
													<p class="_1ungv2r0">This is a Contract/Temp job</p>
												</div>
												<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6n">
													<div class="_1ungv2r0"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7"><span class="_1ungv2r0" data-automation="jobCardLocation"><a href="javascript:void(0);" ddd="/jobs/in-Darwin-River-Dam-NT-0822?classification=6205%2C6092" rel="nofollow" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _8x0b970 _8x0b972" aria-label="Limit results to Darwin River Dam" tabindex="-1" title="Limit results to Darwin River Dam" data-type="location" data-automation="jobLocation" target="_self">Darwin River Dam</a></span><span class="_1ungv2r0" data-automation="jobCardLocation">, <a href="javascript:void(0);" ddd="/jobs/in-All-Darwin-NT?classification=6205%2C6092" rel="nofollow" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _8x0b970 _8x0b972" aria-label="Limit results to Darwin NT" tabindex="-1" title="Limit results to Darwin NT" data-type="location" data-automation="jobLocation" target="_self">Darwin NT</a></span></span>
													</div>
													<div class="_1ungv2r0" aria-label="Salary: Up to $47.83 p.h. + casual penalty rates apply"><span class="_1ungv2r0 _1viagsn4z _1viagsnr _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7 ndjyux0" data-automation="jobSalary"><span class="_1ungv2r0 ndjyux2 _1viagsn4z _1viagsn0 _1viagsnr ndjyux4" style="--ndjyux3:2">Up to $47.83 p.h. + casual penalty rates apply</span></span>
													</div>
													<div class="_1ungv2r0 _1viagsn4v _1viagsn50"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7"><div class="_1ungv2r0 _1viagsn5b _1viagsnh7 _1viagsngf _1viagsn7f _1viagsnhn _1suz4l60"><span class="_1ungv2r0 _1viagsn5j _1viagsn0 _17tnjda0">subClassification: Warehousing, Storage &amp; Distribution</span><a href="javascript:void(0);" ddd="/jobs-in-manufacturing-transport-logistics/warehousing-storage-distribution" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _8x0b970 _8x0b972" aria-label="Limit results to Warehousing, Storage &amp; Distribution in Manufacturing, Transport &amp; Logistics" tabindex="-1" title="Limit results to Warehousing, Storage &amp; Distribution in Manufacturing, Transport &amp; Logistics" data-type="subClassification" data-automation="jobSubClassification" target="_self">Warehousing, Storage &amp; Distribution</a>
														<div class="_1ungv2r0 _1viagsnff"></div><span class="_1ungv2r0 _1viagsn5j _1viagsn0 _17tnjda0">classification: Manufacturing, Transport &amp; Logistics</span><a href="javascript:void(0);" ddd="/jobs-in-manufacturing-transport-logistics" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _8x0b970 _8x0b972" aria-label="Limit results to Manufacturing, Transport &amp; Logistics" tabindex="-1" title="Limit results to Manufacturing, Transport &amp; Logistics" data-type="classification" data-automation="jobClassification" target="_self">(Manufacturing, Transport &amp; Logistics)</a></div>
													</span>
												</div>
										</div><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av22 _1708b944 _3h66av7" data-testid="job-card-teaser" data-automation="jobShortDescription">Toll People is hiring Warehouse Officers in Darwin! Enjoy weekly pay, excellent rates, career growth, and the potential for permanent employment!</span>
										<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6v"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av22 _1708b944 _3h66av7" data-automation="jobListingDate">6h ago</span>
											<div class="_1ungv2r0"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av3 _3h66av1w _1708b944 _3h66av7"><span class="_1ungv2r0 _1viagsn5b"><span class="_1ungv2r0 _1viagsn4z _1viagsn9v _1viagsnhz _1viagsnhv _1viagsnr"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="currentColor" class="_1viagsn57 _1viagsn5f qa05hu0 qa05hu2 qa05hu3 qa05hu4" aria-hidden="true"><path fill-rule="evenodd" clip-rule="evenodd" d="M1 6C1 3.22727 3.22727 1 6 1C8.77273 1 11 3.22727 11 6C11 8.77273 8.77273 11 6 11C3.22727 11 1 8.77273 1 6ZM6.45455 5.72727L8 6.5C8.22727 6.63636 8.31818 6.90909 8.22727 7.09091C8.13636 7.27273 8 7.36364 7.81818 7.36364C7.72727 7.36364 7.68182 7.36364 7.63636 7.31818L5.81818 6.40909C5.63636 6.31818 5.54545 6.18182 5.54545 6V3.27273C5.54545 3 5.72727 2.81818 6 2.81818C6.27273 2.81818 6.45455 3 6.45455 3.27273V5.72727Z"></path></svg></span><span class="_1ungv2r0 _1viagsn4z _1viagsnr">Be an early applicant</span></span>
												</span>
											</div>
										</div>
										</div>
										</article>
										</div>
										<div class="_1ungv2r0" data-search-sol-meta="{&quot;searchRequestToken&quot;:&quot;57ffa085-d70e-4a32-8a9f-976c323d9f9b&quot;,&quot;token&quot;:&quot;0~57ffa085-d70e-4a32-8a9f-976c323d9f9b&quot;,&quot;jobId&quot;:&quot;80312934&quot;,&quot;section&quot;:&quot;MAIN&quot;,&quot;sectionRank&quot;:6,&quot;jobAdType&quot;:&quot;ORGANIC&quot;,&quot;tags&quot;:{&quot;mordor__flights&quot;:&quot;mordor_530&quot;,&quot;mordor__s&quot;:&quot;0&quot;,&quot;seek:chalice:experiments:remote_search_filter&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:behavioural_cues_filters&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:secondary_filters&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:dynamic_pills&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:serp_job_card_info_density_1&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:serp_google_one_tap&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:matched_qualities&quot;:&quot;2&quot;}}">
											<article class="_1ungv2r0 _1ungv2r1 _1viagsn8n _1viagsn8o _1viagsn7j _1viagsn7k _1viagsnav _1viagsnaw _1viagsn9r _1viagsn9s _1viagsnh _1viagsn67 _1viagsn5f _1st1fyjb _1st1fyj9 _1st1fyja _3h66av18 _3h66av1b _1viagsn33 _1viagsn36" data-automation="normalJob" data-testid="job-card" data-job-id="80312934" data-card-type="JobCard" id="jobcard-6" aria-label="3PL Operations" aria-selected="false">
												<div class="_1ungv2r0 _1viagsn4z _1viagsn4x">
													<a href="javascript:void(0);" ddd="/job/80312934?type=standard&amp;ref=search-standalone#sol=857d7a170ac1af738339636c24c2023cdd5e90e2" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _1viagsn5j _1viagsnj _1viagsnk _1viagsnl _1viagsnm _1viagsn7" data-automation="job-list-view-job-link" target="_blank"></a>
												</div>
												<div class="_1ungv2r0 _1viagsn4v _1viagsn51">
													<a href="javascript:void(0);" ddd="/job/80312934?type=standard&amp;ref=search-standalone&amp;origin=jobCard#sol=857d7a170ac1af738339636c24c2023cdd5e90e2" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _1viagsn5j _1viagsnj _1viagsnk _1viagsnl _1viagsnm _1viagsn7 _1st1fyj4" data-automation="job-list-item-link-overlay" data-testid="job-list-item-link-overlay" data-run-click-only="true" target="_self"></a>
												</div>
												<div class="_1ungv2r0 _1viagsn5j _1viagsn0 _17tnjda0">Listed fourteen hours ago</div>
												<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6v">
													<div class="_1ungv2r0 _1viagsn5b _1viagsnh7 _1viagsngr _157hsn62d">
														<div class="_1ungv2r0 _1viagsn4z _1viagsnr _1viagsnp _1viagsni3 _1viagsnb7">
															<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6v">
																<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6r">
																	<div class="_1ungv2r0">
																		<h3 class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av3 _3h66av21 _1708b944 _3h66ava"><div class="_1ungv2r0 _1viagsn5h _1viagsn53"><a href="javascript:void(0);" ddd="/job/80312934?type=standard&amp;ref=search-standalone&amp;origin=cardTitle#sol=857d7a170ac1af738339636c24c2023cdd5e90e2" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _1st1fyje _1st1fyjg" id="job-title-80312934" data-automation="jobTitle" data-testid="job-card-title" data-run-click-only="true" target="_self">3PL Operations</a></div></h3>
																		<div class="_1ungv2r0 _1viagsn7v"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66ava"><div class="_1ungv2r0 _1viagsn5h _1viagsn53"><span class="_1ungv2r0 _1viagsn5j _1viagsn0 _17tnjda0">at<!-- --> </span><a href="javascript:void(0);" ddd="/Navia-Logistics-jobs" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _8x0b970 _8x0b971" aria-label="Jobs at Navia Logistics" title="Jobs at Navia Logistics" data-type="company" data-automation="jobCompany" target="_self">Navia Logistics</a></div>
																		</span>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div class="_1ungv2r0 _1viagsn4z _1viagsnr _1viagsnhv _1viagsnhz _1viagsnb7">
														<div class="_1ungv2r0 _1viagsn5h _1viagsn53">
															<div class="_1ungv2r0 _157hsn60 _157hsn64 _157hsn61 _157hsn618 _157hsn62c _157hsn63g">
																<div class="_1ungv2r0 _1viagsn5f"><button class="_1ungv2r0 _1ungv2r7 _1viagsn8v _1viagsn7r _1viagsnb3 _1viagsn9z _1viagsn7 _1viagsnw _1viagsn5v _1viagsn5f _1viagsn4z _1viagsnz _1viagsny _1viagsn5 _1viagsnib _1viagsn4 _1viagsnh _4z9l0z0 _4z9l0z6 _12i37nn0 uf4ir0" type="button" id="serp-signed-out-save-job-80312934" aria-label="Sign in to save this job" tabindex="0" data-testid="signed-out-save-job" data-automation="signed-out-save-job"><span class="_1ungv2r0 _1viagsnj _1viagsnk _1viagsnl _1viagsnm _1viagsn5j _1viagsni _1viagsn5v _1viagsnx _1viagsn6 di8fio0 _4z9l0z4 _1viagsn4t _1viagsn4u"></span><span class="_1ungv2r0 _1viagsnj _1viagsnk _1viagsnl _1viagsnm _1viagsn5j _1viagsni _1viagsn5v _1viagsnx _1viagsn6 _4z9l0z3 _4z9l0zi _3h66av18 _3h66av1a _1viagsn2t _1viagsn2u"></span><span class="_1ungv2r0 _1viagsnj _1viagsnk _1viagsnl _1viagsnm _1viagsn5j _1viagsni _1viagsn5v _1viagsnx _1viagsn6 _4z9l0z2 _4z9l0zj _3h66av18 _3h66av1a _1viagsn2r _1viagsn2s"></span><span class="_1ungv2r0 _1viagsn4z _1viagsn5f qa05hu0 _3h66avi qa05hu1"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" xml:space="preserve" focusable="false" fill="currentColor" width="16" height="16" class="_1ungv2r0 _1viagsnp _1viagsnn _1viagsn4z _3h66av21" aria-hidden="true"><path d="M19 5.1c.1-1.6-1.1-2.9-2.7-3.1H7.6C6.1 2.1 4.9 3.5 5 5v16c0 .4.2.7.5.9.3.2.7.2 1 0l5.4-3.6 5.4 3.6c.2.1.4.2.6.2.2 0 .3 0 .5-.1.3-.2.5-.5.5-.9l.1-16zm-2 14-4.4-3c-.3-.2-.8-.2-1.1 0l-4.4 3L7 4.9c0-.4.3-.9.7-.9h8.5c.5 0 .8.5.8 1v14.1z"></path></svg></span></button></div>
															</div>
														</div>
													</div>
												</div>
												<div class="_1ungv2r0 _1viagsn5j _1viagsn0 _17tnjda0">
													<p class="_1ungv2r0">This is a Full time job</p>
												</div>
												<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6n">
													<div class="_1ungv2r0"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7"><span class="_1ungv2r0" data-automation="jobCardLocation"><a href="javascript:void(0);" ddd="/jobs/in-Melbourne-Airport-VIC-3045?classification=6205%2C6092" rel="nofollow" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _8x0b970 _8x0b972" aria-label="Limit results to Melbourne Airport" tabindex="-1" title="Limit results to Melbourne Airport" data-type="location" data-automation="jobLocation" target="_self">Melbourne Airport</a></span><span class="_1ungv2r0" data-automation="jobCardLocation">, <a href="javascript:void(0);" ddd="/jobs/in-All-Melbourne-VIC?classification=6205%2C6092" rel="nofollow" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _8x0b970 _8x0b972" aria-label="Limit results to Melbourne VIC" tabindex="-1" title="Limit results to Melbourne VIC" data-type="location" data-automation="jobLocation" target="_self">Melbourne VIC</a></span></span>
													</div>
													<div class="_1ungv2r0" aria-label="Salary: $30 – $37 per hour"><span class="_1ungv2r0 _1viagsn4z _1viagsnr _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7 ndjyux0" data-automation="jobSalary"><span class="_1ungv2r0 ndjyux2 _1viagsn4z _1viagsn0 _1viagsnr ndjyux4" style="--ndjyux3:2">$30 – $37 per hour</span></span>
													</div>
													<div class="_1ungv2r0 _1viagsn4v _1viagsn50"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7"><div class="_1ungv2r0 _1viagsn5b _1viagsnh7 _1viagsngf _1viagsn7f _1viagsnhn _1suz4l60"><span class="_1ungv2r0 _1viagsn5j _1viagsn0 _17tnjda0">subClassification: Warehousing, Storage &amp; Distribution</span><a href="javascript:void(0);" ddd="/jobs-in-manufacturing-transport-logistics/warehousing-storage-distribution" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _8x0b970 _8x0b972" aria-label="Limit results to Warehousing, Storage &amp; Distribution in Manufacturing, Transport &amp; Logistics" tabindex="-1" title="Limit results to Warehousing, Storage &amp; Distribution in Manufacturing, Transport &amp; Logistics" data-type="subClassification" data-automation="jobSubClassification" target="_self">Warehousing, Storage &amp; Distribution</a>
														<div class="_1ungv2r0 _1viagsnff"></div><span class="_1ungv2r0 _1viagsn5j _1viagsn0 _17tnjda0">classification: Manufacturing, Transport &amp; Logistics</span><a href="javascript:void(0);" ddd="/jobs-in-manufacturing-transport-logistics" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _8x0b970 _8x0b972" aria-label="Limit results to Manufacturing, Transport &amp; Logistics" tabindex="-1" title="Limit results to Manufacturing, Transport &amp; Logistics" data-type="classification" data-automation="jobClassification" target="_self">(Manufacturing, Transport &amp; Logistics)</a></div>
													</span>
												</div>
										</div><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av22 _1708b944 _3h66av7" data-testid="job-card-teaser" data-automation="jobShortDescription">The 3PL Operator is responsible for the efficient and accurate management of inventory and warehouse operations for third-party logistics clients.</span>
										<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6v"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av22 _1708b944 _3h66av7" data-automation="jobListingDate">14h ago</span></div>
										</div>
										</article>
										</div>
										<div class="_1ungv2r0" data-search-sol-meta="{&quot;searchRequestToken&quot;:&quot;57ffa085-d70e-4a32-8a9f-976c323d9f9b&quot;,&quot;token&quot;:&quot;0~57ffa085-d70e-4a32-8a9f-976c323d9f9b&quot;,&quot;jobId&quot;:&quot;80090726&quot;,&quot;section&quot;:&quot;MAIN&quot;,&quot;sectionRank&quot;:7,&quot;jobAdType&quot;:&quot;ORGANIC&quot;,&quot;tags&quot;:{&quot;mordor__flights&quot;:&quot;mordor_530&quot;,&quot;mordor__s&quot;:&quot;0&quot;,&quot;seek:chalice:experiments:remote_search_filter&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:behavioural_cues_filters&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:secondary_filters&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:dynamic_pills&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:serp_job_card_info_density_1&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:serp_google_one_tap&quot;:&quot;2&quot;,&quot;seek:chalice:experiments:matched_qualities&quot;:&quot;2&quot;}}">
											<article class="_1ungv2r0 _1ungv2r1 _1viagsn8n _1viagsn8o _1viagsn7j _1viagsn7k _1viagsnav _1viagsnaw _1viagsn9r _1viagsn9s _1viagsnh _1viagsn67 _1viagsn5f _1st1fyjb _1st1fyj9 _1st1fyja _3h66av18 _3h66av1b _1viagsn33 _1viagsn36" data-automation="normalJob" data-testid="job-card" data-job-id="80090726" data-card-type="JobCard" id="jobcard-7" aria-label="Pharmaceutical Voice Pickers - Greystanes" aria-selected="false">
												<div class="_1ungv2r0 _1viagsn4z _1viagsn4x">
													<a href="javascript:void(0);" ddd="/job/80090726?type=standout&amp;ref=search-standalone#sol=fff64ec8ef63173b5b9c5438731f59e5c370d258" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _1viagsn5j _1viagsnj _1viagsnk _1viagsnl _1viagsnm _1viagsn7" data-automation="job-list-view-job-link" target="_blank"></a>
												</div>
												<div class="_1ungv2r0 _1viagsn4v _1viagsn51">
													<a href="javascript:void(0);" ddd="/job/80090726?type=standout&amp;ref=search-standalone&amp;origin=jobCard#sol=fff64ec8ef63173b5b9c5438731f59e5c370d258" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _1viagsn5j _1viagsnj _1viagsnk _1viagsnl _1viagsnm _1viagsn7 _1st1fyj4" data-automation="job-list-item-link-overlay" data-testid="job-list-item-link-overlay" data-run-click-only="true" target="_self"></a>
												</div>
												<div class="_1ungv2r0 _1viagsn5j _1viagsn0 _17tnjda0">Listed ten days ago</div>
												<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6v">
													<div class="_1ungv2r0 _1viagsn5b _1viagsnh7 _1viagsngr _157hsn62d">
														<div class="_1ungv2r0 _1viagsn4z _1viagsnr _1viagsnp _1viagsni3 _1viagsnb7">
															<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6n">
																<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6r">
																	<div class="_1ungv2r0 khwnjl1" data-testid="company-logo-container" data-automation="company-logo-container">
																		<div class="_1ungv2r0 _1viagsn5b _1viagsnn" data-automation="company-logo"><img class="khwnjl0" src="https://bx-branding-gateway.cloud.seek.com.au/26bf4405-5d70-5c94-6d53-c394cc3d2e28.1/serpLogo" loading="lazy"></div>
																	</div>
																</div>
																<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6r">
																	<div class="_1ungv2r0">
																		<h3 class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av3 _3h66av21 _1708b944 _3h66ava"><div class="_1ungv2r0 _1viagsn5h _1viagsn53"><a href="javascript:void(0);" ddd="/job/80090726?type=standout&amp;ref=search-standalone&amp;origin=cardTitle#sol=fff64ec8ef63173b5b9c5438731f59e5c370d258" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _1st1fyje _1st1fyjg" id="job-title-80090726" data-automation="jobTitle" data-testid="job-card-title" data-run-click-only="true" target="_self">Pharmaceutical Voice Pickers - Greystanes</a></div></h3>
																		<div class="_1ungv2r0 _1viagsn7v"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66ava"><div class="_1ungv2r0 _1viagsn5h _1viagsn53"><span class="_1ungv2r0 _1viagsn5j _1viagsn0 _17tnjda0">at<!-- --> </span><a href="javascript:void(0);" ddd="/Toll-People-jobs" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _8x0b970 _8x0b971" aria-label="Jobs at Toll People" title="Jobs at Toll People" data-type="company" data-automation="jobCompany" target="_self">Toll People</a></div>
																		</span>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div class="_1ungv2r0 _1viagsn4z _1viagsnr _1viagsnhv _1viagsnhz _1viagsnb7">
														<div class="_1ungv2r0 _1viagsn5h _1viagsn53">
															<div class="_1ungv2r0 _157hsn60 _157hsn64 _157hsn61 _157hsn618 _157hsn62c _157hsn63g">
																<div class="_1ungv2r0 _1viagsn5f"><button class="_1ungv2r0 _1ungv2r7 _1viagsn8v _1viagsn7r _1viagsnb3 _1viagsn9z _1viagsn7 _1viagsnw _1viagsn5v _1viagsn5f _1viagsn4z _1viagsnz _1viagsny _1viagsn5 _1viagsnib _1viagsn4 _1viagsnh _4z9l0z0 _4z9l0z6 _12i37nn0 uf4ir0" type="button" id="serp-signed-out-save-job-80090726" aria-label="Sign in to save this job" tabindex="0" data-testid="signed-out-save-job" data-automation="signed-out-save-job"><span class="_1ungv2r0 _1viagsnj _1viagsnk _1viagsnl _1viagsnm _1viagsn5j _1viagsni _1viagsn5v _1viagsnx _1viagsn6 di8fio0 _4z9l0z4 _1viagsn4t _1viagsn4u"></span><span class="_1ungv2r0 _1viagsnj _1viagsnk _1viagsnl _1viagsnm _1viagsn5j _1viagsni _1viagsn5v _1viagsnx _1viagsn6 _4z9l0z3 _4z9l0zi _3h66av18 _3h66av1a _1viagsn2t _1viagsn2u"></span><span class="_1ungv2r0 _1viagsnj _1viagsnk _1viagsnl _1viagsnm _1viagsn5j _1viagsni _1viagsn5v _1viagsnx _1viagsn6 _4z9l0z2 _4z9l0zj _3h66av18 _3h66av1a _1viagsn2r _1viagsn2s"></span><span class="_1ungv2r0 _1viagsn4z _1viagsn5f qa05hu0 _3h66avi qa05hu1"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" xml:space="preserve" focusable="false" fill="currentColor" width="16" height="16" class="_1ungv2r0 _1viagsnp _1viagsnn _1viagsn4z _3h66av21" aria-hidden="true"><path d="M19 5.1c.1-1.6-1.1-2.9-2.7-3.1H7.6C6.1 2.1 4.9 3.5 5 5v16c0 .4.2.7.5.9.3.2.7.2 1 0l5.4-3.6 5.4 3.6c.2.1.4.2.6.2.2 0 .3 0 .5-.1.3-.2.5-.5.5-.9l.1-16zm-2 14-4.4-3c-.3-.2-.8-.2-1.1 0l-4.4 3L7 4.9c0-.4.3-.9.7-.9h8.5c.5 0 .8.5.8 1v14.1z"></path></svg></span></button></div>
															</div>
														</div>
													</div>
												</div>
												<div class="_1ungv2r0 _1viagsn5j _1viagsn0 _17tnjda0">
													<p class="_1ungv2r0">This is a Casual/Vacation job</p>
												</div>
												<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6n">
													<div class="_1ungv2r0"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7"><span class="_1ungv2r0" data-automation="jobCardLocation"><a href="javascript:void(0);" ddd="/jobs/in-Greystanes-NSW-2145?classification=6205%2C6092" rel="nofollow" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _8x0b970 _8x0b972" aria-label="Limit results to Greystanes" tabindex="-1" title="Limit results to Greystanes" data-type="location" data-automation="jobLocation" target="_self">Greystanes</a></span><span class="_1ungv2r0" data-automation="jobCardLocation">, <a href="javascript:void(0);" ddd="/jobs/in-All-Sydney-NSW?classification=6205%2C6092" rel="nofollow" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _8x0b970 _8x0b972" aria-label="Limit results to Sydney NSW" tabindex="-1" title="Limit results to Sydney NSW" data-type="location" data-automation="jobLocation" target="_self">Sydney NSW</a></span></span>
													</div>
													<div class="_1ungv2r0" aria-label="Salary: $44 - $49 p.h."><span class="_1ungv2r0 _1viagsn4z _1viagsnr _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7 ndjyux0" data-automation="jobSalary"><span class="_1ungv2r0 ndjyux2 _1viagsn4z _1viagsn0 _1viagsnr ndjyux4" style="--ndjyux3:2">$44 - $49 p.h.</span></span>
													</div>
													<div class="_1ungv2r0 _1viagsn4v _1viagsn50"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7"><div class="_1ungv2r0 _1viagsn5b _1viagsnh7 _1viagsngf _1viagsn7f _1viagsnhn _1suz4l60"><span class="_1ungv2r0 _1viagsn5j _1viagsn0 _17tnjda0">subClassification: Pickers &amp; Packers</span><a href="javascript:void(0);" ddd="/jobs-in-manufacturing-transport-logistics/pickers-packers" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _8x0b970 _8x0b972" aria-label="Limit results to Pickers &amp; Packers in Manufacturing, Transport &amp; Logistics" tabindex="-1" title="Limit results to Pickers &amp; Packers in Manufacturing, Transport &amp; Logistics" data-type="subClassification" data-automation="jobSubClassification" target="_self">Pickers &amp; Packers</a>
														<div class="_1ungv2r0 _1viagsnff"></div><span class="_1ungv2r0 _1viagsn5j _1viagsn0 _17tnjda0">classification: Manufacturing, Transport &amp; Logistics</span><a href="javascript:void(0);" ddd="/jobs-in-manufacturing-transport-logistics" class="_1ungv2r0 _1ungv2rf  _1ungv2r0 _1ungv2rf _8x0b970 _8x0b972" aria-label="Limit results to Manufacturing, Transport &amp; Logistics" tabindex="-1" title="Limit results to Manufacturing, Transport &amp; Logistics" data-type="classification" data-automation="jobClassification" target="_self">(Manufacturing, Transport &amp; Logistics)</a></div>
													</span>
												</div>
										</div>
										<ul class="_1ungv2r0 _1ungv2r3 _1viagsn5b _1viagsnhf _1viagsn6n _1viagsni7">
											<li class="_1ungv2r0 _1viagsn5b">
												<div class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7">
													<div class="_1ungv2r0 _1viagsn5b _1viagsngj _1viagsn4 hb8hjj1" aria-hidden="true">
														<div class="_1ungv2r0 _1viagsn5v iz7cpl0 iz7cpl2"></div>
													</div>
												</div>
												<div class="_1ungv2r0 _1viagsnr _1viagsnp _1viagsnb7"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7">Pharmaceutical warehouse environment, state of the art facilities</span></div>
											</li>
											<li class="_1ungv2r0 _1viagsn5b">
												<div class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7">
													<div class="_1ungv2r0 _1viagsn5b _1viagsngj _1viagsn4 hb8hjj1" aria-hidden="true">
														<div class="_1ungv2r0 _1viagsn5v iz7cpl0 iz7cpl2"></div>
													</div>
												</div>
												<div class="_1ungv2r0 _1viagsnr _1viagsnp _1viagsnb7"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7">Temp to Perm opportunities available for proven performers</span></div>
											</li>
											<li class="_1ungv2r0 _1viagsn5b">
												<div class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7">
													<div class="_1ungv2r0 _1viagsn5b _1viagsngj _1viagsn4 hb8hjj1" aria-hidden="true">
														<div class="_1ungv2r0 _1viagsn5v iz7cpl0 iz7cpl2"></div>
													</div>
												</div>
												<div class="_1ungv2r0 _1viagsnr _1viagsnp _1viagsnb7"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av21 _1708b944 _3h66av7">Attractive Pay Rates</span></div>
											</li>
										</ul><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av22 _1708b944 _3h66av7" data-testid="job-card-teaser" data-automation="jobShortDescription">Toll People are currently seeking experienced voice pickers for casual positons in Greystanes. You will be working within a pharmaceutical warehouse.</span>
										<div class="_1ungv2r0 _1viagsn5b _1viagsnhf _1viagsn6v"><span class="_1ungv2r0 _1viagsn4z _3h66av0 _3h66av1 _3h66av22 _1708b944 _3h66av7" data-automation="jobListingDate">10d ago</span></div>
										</div>
										</article>
										</div>
										
										
										</div>
					<!-- //// -->
					<div class="xemthemhome" style='margin-top: 80PX;'><a href="javascript:void(0);" title="View more"></a></div>
					<!-- ./. Tin việc làm nước ngoài mới nhất -->
					
					
				</div>
				
			</div>
		</div>
		
		<div class="call-mobile" style="bottom: 116PX;display: none;">
			<a class="" href="tel:0348148888"> <img src="/img/gw/phone_mobile.png" alt="phone" title="phone"> 0348.14.8888</a>
		</div>
		
		<div class="call-mobile" style="display: none;">
			<a class="" href="tel:0327089999"> <img src="/img/gw/phone_mobile.png" alt="phone" title="phone"> 0327.08.9999</a>
		</div>
		
		<!--div class="call-mobile" style="bottom: 117px;">
			<a class="" href="tel:0587236666"> <img src="/img/gw/phone_mobile.png" alt="phone" title="phone"> 0587236666</a>
		</div-->
		
	</div>
</template>

<script>
export default {
	data() {
		return {
			addlist: [
				{
					0: '北京',
					1: '上海',
					2: '广州',
					3: '深圳'
				},
				{
					0: '南京',
					1: '长沙',
					2: '重庆',
					3: '西安'
				}
			]
		};
	},
	methods: {
		addgo(data) {
			if (!localStorage.getItem('token')) {
				this.$router.push({ path: '/Login' });
			} else {
				this.$router.push({ path: '/list?id=' + data.id + '&name=' + data.name });
			}
		},
		getAddress() {
			this.$http({
				method: 'get',
				url: 'address_list'
			}).then(res => {
				this.addlist = res.data;
			});
		}
	},
	created() {
		this.getAddress();
	}
};
</script>

<style src='../../assets/css/ttc.css' scoped></style>
<!-- <style src='../../assets/css/responsive.css' scoped></style> -->
<!-- <style src='../../assets/css/bootstrap.min.css' scoped></style> -->

<style lang="less" scoped>
	// @import "../../assets/css/ttc.css";
	// @import "../../assets/css/responsive.css";
.page {
	position: absolute !important;
	top: 0;
	left: 0;
	right: 0;
	background-color: #f2f2f5;
}
.nav-bar {
	background: linear-gradient(90deg,#500cfd,#9e9ce7);
	height: 100px;
}
.van-nav-bar {
	line-height: 50px;
}
::v-deep .van-nav-bar__title {
	max-width: 60%;
	margin: 0 auto;
	color: #ffffff;
	font-size: 35px;
}
::v-deep .van-nav-bar__content {
	height: 100px;
}
.van-sidebar {
	width: 180px;
}
.convention-hall {
	display: flex;
	flex-direction: column;
	bottom: 20px;
	background: #f2f2f5;
}
::v-deep .van-tab {
	font-size: 30px;
	line-height: 100px;
	font-weight: bold;
}
::v-deep .van-tabs__line {
	background-color: #500cfd;
}
::v-deep .van-tabs--line .van-tabs__wrap {
	height: 100px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
	padding: 0 23px;
}
.card {
	background-color: #8a637d;
	padding: 0.625rem;
	width: 95%;
	color: white;
	font-size: 0.8125rem;
	margin: 0.625rem auto;
	border-radius: 0.375rem;
}
::v-deep .van-row--flex {
	height: 80px;
	line-height: 80px;
}
.rig-box {
	width: 95%;
	margin: 0.625rem auto;
}
.rig-title {
	color: #0bdab0;
	font-size: 1.125rem;
}
.rig-content {
	font-size: 20px;
	// margin-top: 10px;
}
.address {
	width: 94%;
	margin: 0 auto;
}
.van-cell {
    padding: 4vw 2.933vw;
    font-size: 4vw;
    line-height: 4vw;
}
.pageone-title h2 {
margin: 0px;
    font-size: 17px;
    text-transform: uppercase;
    font-weight: bold;
    background: #fff;
    display: inline-block;
    padding-right: 10px;
	}
.pageone-title h2 {
    font-size: 15PX;
    display: block;
	text-transform: uppercase;
	    font-weight: bold;
}
// .container {
//     font-family: Inter,sans-serif;
//     font-weight: 400;
//     color: #fff!important;
// }
.container {
    // font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    // font-size: 14PX;
    // line-height: 1.42857143;
    // color: #333;
    background-color: #fff;
	z-index: 1!important;
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}
.pageone-tab .nav-tabs>li {
    width: 50%!important;
}
.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
    float: left;
}
.nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
.nav-tabs {
    border-bottom: 1px solid #ddd;
}
table {
    border-spacing: 0;
    border-collapse: collapse;
}
.tab-content {
    display: block;
    // overflow-x: scroll;
}
.zwBox{
	width: 730px;
	margin-top: 20px;
	.zwBox-item{
		position: relative;
		width: 680px;
		border: 2px solid #ddd;
		margin: 30px auto 30px;
		border-radius: 30px;
		box-sizing: border-box;
		overflow-wrap: break-word;
		white-space: pre-wrap;
		word-wrap: break-word;
		padding: 40px 50px;
		text-align: left;
		box-shadow: 0 3px 2px #eee;
		.zwBox-item-top{
			display: flex;
			flex-direction: row-reverse;
			font-size: 40px;
			color: #5a6881;
		}
		.zwBox-item-logo{
			width:200px;
			height:100px;
			img{
				width:100%;
				height:100%;
			}
		}
		.zwBox-item-title{
			margin-top: 20px;
			font-size: 35px;
			color: #2e3849;
			font-weight: bold;
		}
		.zwBox-item-tips{
			margin-top: 20px;
			font-size: 30px;
			color: #2e3849;
		}
		.zwBox-item-text{
			margin-top: 20px;
			font-size: 27px;
			color: #2e3849;
		}
		.zwBox-item-ul{
			margin-top: 30px;
			.zwBox-item-banci{
				font-size: 27px;
				color: #2e3849;
				display: flex;
				align-items: center;
				.zwBox-item-banci-slider{
					background-color: #2e3849;
					width:8px;
					height:8px;
					border-radius: 50%;
					margin-right: 20px;
				}
			}
		}
		.zwBox-item-jiesao{
			margin-top: 20px;
			font-size: 26px;
			color: #5a6881;
		}
		.zwBox-item-time{
			margin-top: 25px;
			font-size: 28px;
			color: #5a6881;
		}
	}
}
.donhang-home tr td{
	word-break: break-all;
}
</style>
